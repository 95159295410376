import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  useMap,
  Popup,
  useMapEvents,
} from "react-leaflet";
import { MarkerLayer, Marker } from "react-leaflet-marker";
import cssStyles from "./MapComponent.module.css";
import "leaflet/dist/leaflet.css";
import markerIcon from "../../assets/leaflet/marker-icon.png";
function LocationMarker(props) {
  const [position, setPosition] = useState(null);
  const { lat, lng } = props;

  const map = useMapEvents({
    click(e) {
      setPosition(e.latlng);
      if (props.getPosition) {
        props.getPosition(e.latlng);
      }
      map.flyTo(e.latlng, map.getZoom());
      //map.locate();
      //map.get
    },
    locationfound(e) {
      setPosition(e.latlng);
      if (props.getPosition) {
        props.getPosition(e.latlng);
      }
      map.flyTo(e.latlng, map.getZoom());
    },
  });

  useEffect(() => {
    if (lat && lng) {
      //console.log("SETTING A POSITION: ", lat, lng);
      setPosition({ lat, lng });
      map.flyTo({ lat, lng }, map.getZoom());
    }
  }, [lat, lng]);

  return position === null ? null : (
    <Marker position={position}>
      <img src={markerIcon} style={{ transform: "translate(0,-41px)" }}></img>
    </Marker>
  );
}
const MapInputComponent = (props) => {
  const [initialPosition, setInitialPosition] = useState(null);

  useEffect(() => {
    if (!initialPosition) {
      if (props.lat && props.lng) {
        // console.log(
        //   "MAP INPUT COMPONENT INITIAL POSITION: ",
        //   props.lat,
        //   props.lng
        // );
        setInitialPosition([props.lat, props.lng]);
      } else {
        onGetInitialPositionHandler();
      }
    }
  }, [initialPosition, props.lat, props.lng]);

  const onGetInitialPositionHandler = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setInitialPosition([position.coords.latitude, position.coords.longitude]);
    });
  };

  return (
    <React.Fragment>
      {initialPosition && (
        <MapContainer
          className={cssStyles.map}
          center={initialPosition}
          zoom={16}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerLayer>
            {props.getPosition && (
              <LocationMarker
                lat={props.lat}
                lng={props.lng}
                getPosition={props.getPosition}
              />
            )}
          </MarkerLayer>
        </MapContainer>
      )}
      {!initialPosition && <div></div>}
    </React.Fragment>
  );
};

export default MapInputComponent;
