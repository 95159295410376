import options from "../../../global-options";
const PhotoItem = (props) => {
  const { foto } = props;
  return (
    <div className="position-relative col">
      <img
        className="img-thumbnail"
        src={options.publicURL + "/" + foto.url}
        key={foto.id}
      ></img>
      <button
        onClick={(e) => props.action(foto.id)}
        className="btn btn-danger position-absolute bottom-0 end-0 mb-2 me-2"
      >
        <i className="bi bi-x-lg text-light"></i>
      </button>
    </div>
  );
};

export default PhotoItem;
