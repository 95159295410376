import { createSlice } from "@reduxjs/toolkit";
import User from "../../../model/User";
import options from "../../../global-options";

const initialState = {
  authData: {
    user: null,

    token: "",
    expiresAt: 0,
  },
  isLoading: false,
  registerSuccess: false,
  registerError: "",
  loginError: "",
  logoutTimerId: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    onRegisterStart(state, action) {
      state.isLoading = true;
    },
    onRegisterSuccess(state, action) {
      state.user = action.payload;
      state.registerSuccess = true;
      state.isLoading = false;
    },
    onRegisterFailure(state, action) {
      state.registerSuccess = false;
      state.registerError = action.payload;
      state.isLoading = false;
    },
    clearRegisterSuccess(state, action) {
      state.registerSuccess = false;
    },
    onAuthStart(state, action) {
      state.isLoading = true;
    },
    onAuthSuccess(state, action) {
      state.authData = action.payload;
      state.isLoading = false;
    },
    onAuthFailure(state, action) {
      state.loginError = action.payload;
      state.isLoading = false;
    },
    clearAuthError(state, action) {
      state.loginError = null;
    },
    setLogoutTimerId(state, action) {
      state.logoutTimerId = action.payload;
    },
    setAuthData(state, action) {
      state.authData = action.payload;
    },
    setLoggedUser(state, action) {
      state.user = action.payload;
    },
  },
});

const setLogoutTimer = (expiresAt) => {
  return async (dispatch, getState) => {
    const now = new Date();

    //console.log("EXPIRES AT: ", expiresAt);
    const diff = expiresAt - now.getTime();

    //console.log("DIFF: ", diff);
    if (diff < 60 * 1000) {
      dispatch(logout());
      return false;
    } else {
      const timerId = setTimeout(() => {
        dispatch(logout());
      }, diff);

      dispatch(setLogoutTimerId(timerId));
    }

    return true;
  };
};

export const signup = (signupData) => {
  return async (dispatch, getState) => {
    dispatch(onRegisterStart());
    const response = await fetch(`${options.baseURL}/register`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(signupData),
    });

    let responseData = await response.json();
    if (response.status === 201) {
      dispatch(onRegisterSuccess(new User(responseData)));
    } else {
      if ("hydra:description" in responseData) {
        dispatch(onRegisterError(responseData["hydra:description"]));
      } else {
        dispatch(onRegisterError("Erro Desconhecido"));
      }
    }
  };
};

export const login = (username, password) => {
  return async (dispatch, getState) => {
    dispatch(onAuthStart());
    const { logoutTimerId } = getState().auth;
    const response = await fetch(`${options.baseURL}/login_check`, {
      method: "POST",
      body: JSON.stringify({
        username,
        password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const responseData = await response.json();

    //console.log("LOGIN RESPONSE DATA: ", responseData.user);

    if ("token" in responseData) {
      const expiresAt = responseData.exp * 1000;

      const authData = {
        user: new User(responseData.user),
        token: responseData.token,
        expiresAt,
      };
      localStorage.setItem("authData", JSON.stringify(authData));
      if (logoutTimerId) {
        clearTimeout(logoutTimerId);
        dispatch(setLogoutTimerId(null));
      }
      dispatch(setLogoutTimer(expiresAt));
      dispatch(onAuthSuccess(authData));
    } else {
      dispatch(
        onAuthFailure("Falha na autenticação. Usuário e/ou senha incorretos.")
      );
    }
  };
};

export const autoLogin = () => {
  //console.log("Auto Login");
  return (dispatch, getState) => {
    const { logoutTimerId } = getState().auth;
    const stringAuthData = localStorage.getItem("authData");
    if (stringAuthData) {
      const authData = JSON.parse(stringAuthData);

      const expiresAt = authData.expiresAt;

      if (logoutTimerId) {
        clearTimeout(logoutTimerId);
        dispatch(setLogoutTimerId(null));
      }

      dispatch(setLogoutTimer(expiresAt));

      dispatch(onAuthSuccess(authData));
    }
  };
};

export const fetchUserProfile = () => {
  return async (dispatch, getState) => {
    //dispatch(setIsLoading(true));
    const { token } = getState().auth.authData;
    //console.log("TOKEN: ", token);
    if (token) {
      //console.log("TOKEN EXISTS");
      const response = await fetch(`${options.baseURL}/profile`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 401) {
        dispatch(logout());
      }
      const responseData = await response.json();

      dispatch(setLoggedUser(new User(responseData)));
      //setIsLoading(false);
    }
  };
};

export const logout = () => {
  return async (dispatch, getState) => {
    const authState = getState().auth;
    const token = authState.authData.token;
    const logoutTimerId = authState.logoutTimerId;

    // console.log("Logging out with token: ", token);
    // console.log("Logout Timer Id: ", logoutTimerId);
    const response = await fetch(`${options.baseURL}/logout`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log("LOGOUT RESPONSE STATUS: ", response.status);

    dispatch(
      setAuthData({
        username: "",
        expiresAt: 0,
        token: "",
      })
    );
    clearTimeout(logoutTimerId);
    dispatch(setLogoutTimerId(null));
    localStorage.removeItem("authData");
  };
};

export const selectAuthStore = (state) => state.auth;

export const {
  onRegisterStart,
  onRegisterSuccess,
  onRegisterError,
  clearRegisterSuccess,
  onAuthSuccess,
  onAuthFailure,
  setLogoutTimerId,
  setAuthData,
  onAuthStart,
  clearAuthError,
  setLoggedUser,
  setIsLoading,
} = authSlice.actions;

export default authSlice.reducer;
