import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styles from "./NavbarComponent.module.css";
import { selectAuthStore, logout } from "../../store/redux/auth";

const NavbarComponent = () => {
  const authStore = useSelector(selectAuthStore);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authStore.authData.token) {
      navigate("/");
    }
  }, [authStore.authData.token]);

  const onLogoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };
  return (
    <nav
      className={`navbar navbar-expand-md navbar-dark bg-dark ${styles["main-nav"]}`}
      aria-label="Fourth navbar example"
    >
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          SF - Imobiliaria
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarsExample04"
          aria-controls="navbarsExample04"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarsExample04">
          {/*<ul className="navbar-nav me-auto mb-2 mb-md-0">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="#">
                Início
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Sobre esta página
              </a>
            </li>
  </ul>*/}

          {!authStore.authData.token && (
            <div className="collapse navbar-collapse" id="navbarsExample04">
              <ul className="navbar-nav ms-auto mb-2 mb-md-0">
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/login"
                  >
                    <i
                      className="bi bi-box-arrow-in-right pe-2"
                      style={{ color: "#fff" }}
                    ></i>
                    Entrar
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/register">
                    <i
                      className="bi bi-person-add pe-2"
                      style={{ color: "inherit" }}
                    ></i>
                    Cadastrar-se
                  </Link>
                </li>
              </ul>
            </div>
          )}
          {authStore.authData.token && (
            <div className="collapse navbar-collapse" id="navbarsExample04">
              <ul className="navbar-nav ms-auto mb-2 mb-md-0">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="dropdown03"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Olá, {authStore.authData.user.username}
                  </Link>
                  <ul className="dropdown-menu " aria-labelledby="dropdown03">
                    <li>
                      <Link className="dropdown-item" to="/dashboard">
                        <i className="bi bi-house pe-2"></i>
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider"></hr>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/dashboard">
                        <i className="bi bi-person-badge pe-2"></i>
                        Meu Perfil
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider"></hr>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/dashboard/meus-imoveis"
                      >
                        <i className="bi bi-building pe-2"></i>
                        Meus Imóveis
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/dashboard/meus-imoveis"
                      >
                        <i className="bi bi-building-add pe-2"></i>
                        Novo Imóvel
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider"></hr>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/dashboard/favoritos">
                        <i className="bi bi-star pe-2"></i>
                        Favoritos
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a
                    style={{ cursor: "pointer" }}
                    className="nav-link text-danger"
                    onClick={onLogoutHandler}
                  >
                    <i className="bi bi-box-arrow-right text-danger pe-2"></i>
                    Sair
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavbarComponent;
