import SpinnerComponent from "./SpinnerComponent";
import styles from "./OverlaySpinner.module.css";
const OverlaySpinner = () => {
  return (
    <div
      className={`${styles["overlay"]} d-flex justify-content-center align-items-center`}
    >
      <div className="card">
        <div className="card-body">
          <SpinnerComponent theme="lds-blue" />
        </div>
      </div>
    </div>
  );
};

export default OverlaySpinner;
