import React, { useState } from "react";
import { redirect } from "react-router-dom";

import User from "../model/User";

const AuthContext = React.createContext(null);

export default AuthContext;
export const AuthContextProvider = (props) => {
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [registerError, setRegisterError] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [token, setToken] = useState(null);
  const [timerId, setTimerId] = useState(null);
  const [authData, setAuthData] = useState({
    username: "",
    expiresAt: 0,
    token: "",
  });

  const [user, setUser] = useState(null);

  const baseURL = "http://localhost:8000/api/v2";

  const onAuthSuccessHandler = (responseData) => {
    const expiresAt = responseData.exp * 1000;

    const username = responseData.username;
    //console.log("DIFF: ", diff);
    if (!setLogoutTimer(expiresAt)) {
      logout();
    } else {
      setAuthData({
        username,
        expiresAt: expiresAt,
        token: responseData.token,
      });

      localStorage.setItem(
        "authData",
        JSON.stringify({
          username,
          expiresAt: expiresAt,
          token: responseData.token,
        })
      );
    }
  };

  const autoLogin = () => {
    const stringAuthData = localStorage.getItem("authData");
    if (stringAuthData) {
      const authData = JSON.parse(stringAuthData);

      const expiresAt = authData.expiresAt;

      if (setLogoutTimer(expiresAt)) {
        setAuthData(authData);
      }
    }
  };

  const fetchUserProfile = async () => {
    const response = await fetch(`${baseURL}/profile`, {
      headers: { Authorization: `Bearer ${authData.token}` },
    });

    if (response.status === 401) {
      //await logout();
    }
    const responseData = await response.json();

    setUser(new User(responseData));
  };

  const setLogoutTimer = (expiresAt) => {
    const now = new Date();

    const diff = expiresAt - now.getTime();

    if (diff < 60 * 1000) {
      logout();
      return false;
    } else {
      const timerId = setTimeout(() => {
        logout();
      }, diff);

      setTimerId(timerId);
    }

    return true;
  };

  const logout = async () => {
    console.log("Logging out...");
    await fetch(`${baseURL}/logout`);
    setAuthData({
      username: "",
      expiresAt: 0,
      token: "",
    });
    localStorage.removeItem("authData");
  };

  const authState = {
    user,
    token,
    authData,
    registerError,
    registerSuccess,
    loginError,
    setLoginError,
    baseURL,
    setRegisterSuccess,
    signup: async (signupData) => {
      const response = await fetch(`${baseURL}/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(signupData),
      });
      //console.log("RESPONSE STATUS: ", response.status);

      let responseData = await response.json();
      if (response.status === 201) {
        setUser(new User(responseData));
        setRegisterSuccess(true);
        return true;
      } else {
        if ("hydra:description" in responseData) {
          setRegisterError(responseData["hydra:description"]);
        } else {
          setRegisterError("Erro Desconhecido");
        }
      }
      //console.log("SIGNUP RESPONSE: ", responseData);
      return false;
    },
    login: async (username, password) => {
      const response = await fetch("http://localhost:8000/api/v2/login_check", {
        method: "POST",
        body: JSON.stringify({
          username,
          password,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const responseData = await response.json();
      //console.log("LOGIN RESPONSE DATA: ", responseData);

      //console.log("LOGIN STATUS: ", responseData.status);
      if ("token" in responseData) {
        //console.log("Logged In Successfully!!");

        //redirect("/dashboard");
        onAuthSuccessHandler(responseData);
        //setToken(responseData.token);
      } else {
        setLoginError("Falha na autenticação. Usuário e/ou senha incorretos.");
      }
    },
    autoLogin,
    fetchUserProfile,
    logout,
  };
  return (
    <AuthContext.Provider value={authState}>
      {props.children}
    </AuthContext.Provider>
  );
};
