import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Favorite from "../../../model/Favorite";
import Imovel from "../../../model/Imovel";
import options from "../../../global-options";
//import { redirect } from "react-router-dom";

// import { SEARCH_IMOVEIS_REQUEST, SEARCH_IMOVEIS_RESPONSE } from "./actions";
export const OPERATION_SUCCESS = "SUCCESS";
export const OPERATION_FAILURE = "FAILURE";
const initialState = {
  imoveis: [],
  detailImovel: null,
  editImovel: null,
  editImovelFotoPrincipal: null,
  editImovelFotos: [],
  userImoveis: [],
  userFavoritos: [],
  totalUserImoveis: 0,
  totalUserFavoritos: 0,
  isLoading: false,
  isLoadingPhotos: false,
  operationStatus: null,
  saveSuccess: false,
  saveError: null,
  removeSuccess: false,
  removeError: null,
  fetchError: null,
  //baseURL: "http://localhost:8000/api/v2",
  status: "",
  searchParams: {
    uf: "",
    cidade: "",
    bairro: "",
    desc: "",
    paraAlugar: false,
    imovelComercial: false,
    categoria: "todas",
    page: 1,
    dormitorios: 0,
    banheiros: 0,
    cozinhas: 0,
    garagens: 0,
  },
  paginationData: null,
  userImoveisPaginationData: null,
};

// export default function imovelReducer(state = initialState, action) {
//   switch (action.type) {
//     case SEARCH_IMOVEIS_REQUEST: {
//       return { ...state, isLoading: true };
//     }
//     case SEARCH_IMOVEIS_RESPONSE: {
//       return { ...state, imoveis: action.payload, isLoading: false };
//     }
//     default: {
//       return state;
//     }
//   }
// }

export const imovelSlice = createSlice({
  name: "imovel",
  initialState, // initialState: initialState

  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsLoadingPhotos(state, action) {
      state.isLoadingPhotos = action.payload;
    },

    startSearchRequest: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.isLoading = true;
    },

    setLoadedImoveis: (state, action) => {
      state.imoveis = action.payload;
      state.isLoading = false;
    },

    updateSearchParams(state, action) {
      //console.log("UPDATE SEARCH PARAMS ACTION: ", action);
      state.searchParams[action.payload.param] = action.payload.value;
    },

    setUserImoveis(state, action) {
      //console.log("ACTION PAYLOAD: ", action);
      state.userImoveis = action.payload.imoveis;
      state.totalUserImoveis = action.payload.count;
    },

    setUserFavoritos(state, action) {
      state.userFavoritos = action.payload.favoritos;
      state.totalUserFavoritos = action.payload.count;
    },
    setPaginationData(state, action) {
      state.paginationData = action.payload;
    },
    setUserImoveisPaginationData(state, action) {
      state.userImoveisPaginationData = action.payload;
    },
    setOperationStatus(state, action) {
      state.operationStatus = action.payload;
    },
    onSaveImovelSuccess(state, action) {
      state.saveSuccess = true;
      state.userImoveis.push(new Imovel(action.payload));
    },
    onSaveImovelError(state, action) {
      state.saveError = "Erro ao salvar o Imóvel: " + action.payload.message;
      state.saveSuccess = false;
    },
    setDetailImovel(state, action) {
      state.detailImovel = action.payload;
    },
    setEditImovel(state, action) {
      state.editImovel = action.payload;
    },
    setEditImovelFotoPrincipal(state, action) {
      state.editImovelFotoPrincipal = action.payload;
    },
    setEditImovelFotos(state, action) {
      state.editImovelFotos = action.payload ? action.payload : [];
    },
    refreshEditImovelFotoPrincipal(state, action) {
      state.editImovelFotoPrincipal = action.payload;
    },
    refreshEditImovelFotos(state, action) {
      state.editImovelFotos = action.payload ? action.payload : [];
    },
    onUpdateImovelSuccess(state, action) {
      state.saveSuccess = true;
      const updatedImovel = action.payload.updatedImovel;
      const updatedImovelId = updatedImovel;

      const updatedImovelIndex = state.userImoveis.findIndex(
        (imovel) => imovel.id === updatedImovelId
      );

      state.userImoveis[updatedImovelIndex] = { ...updatedImovel };
    },
    updateImovelInCache(state, action) {
      const updatedImovel = action.payload;
      let imovelIndex = state.imoveis.findIndex(
        (imovel) => imovel.id === updatedImovel.id
      );

      if (imovelIndex !== -1) {
        state.imoveis[imovelIndex] = updatedImovel;
      }

      imovelIndex = state.userImoveis.findIndex(
        (imovel) => imovel.id === updatedImovel.id
      );

      if (imovelIndex !== -1) {
        state.userImoveis[imovelIndex] = updatedImovel;
      }
    },

    setSaveStatus(state, action) {
      state.saveSuccess = action.payload;
    },
    setSaveError(state, action) {
      state.saveError = action.payload;
    },
    setFetchError(state, action) {
      state.fetchError = action.payload;
    },
    setRemoveSuccess(state, action) {
      state.removeSuccess = action.payload;
    },
    setRemoveError(state, action) {
      state.removeError = action.payload;
    },
    checkAuth(state, action) {
      //console.log("STATE: ", state);
      //redirect("/login");
    },
  },
  extraReducers(builder) {
    builder
      .addCase(onSearch.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(onSearch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = "success";
        state.imoveis = action.payload.imoveis;
        state.paginationData = action.payload.paginationData;
      })
      .addCase(onSearch.rejected, (state, action) => {
        state.status = "error";
        state.isLoading = false;
      });
  },
});

// export const onSearch = (searchParams) => {
//   return async (dispatch, getState) => {
//     dispatch(startSearchRequest());
//     const state = getState();

//     const baseURL = state.imovel.baseURL;

//     const bairro = searchParams.bairro ? searchParams.bairro : "";
//     const desc = searchParams.desc ? searchParams.desc : "";
//     const url = `${baseURL}/public/imoveis?endereco.uf=${searchParams.uf}&endereco.cidade=${searchParams.cidade}&endereco.bairro=${bairro}&descricao=${desc}&order[id]=desc`;

//     const response = await fetch(url, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });

//     const responseData = await response.json();

//     let imoveis = [];
//     if ("hydra:member" in responseData) {
//       imoveis = responseData["hydra:member"].map(
//         (imovelData) => new Imovel(imovelData)
//       );
//     }

//     //console.log("IMOVEIS IN ACTION: ", imoveis);

//     dispatch(setLoadedImoveis(imoveis));
//   };
// };

export function fetchUserImoveis(page = 1) {
  return async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const state = getState();
    const authState = state.auth;

    const { user, token } = authState.authData;

    const response = await fetch(
      `${options.baseURL}/imoveis?proprietario.id=${user.id}&page=${page}`,
      {
        headers: {
          "Content-Type": "application/json",
          //Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseData = await response.json();

    let userImoveis = [];
    let count = 0;
    let paginationData = null;
    //console.log("IMOVEIS RESPONSE DATA", responseData);
    if ("hydra:member" in responseData) {
      //console.log("Setting imoveis: ", responseData);
      userImoveis = responseData["hydra:member"].map(
        (imovelData) => new Imovel(imovelData)
      );
      count = responseData["hydra:totalItems"];

      paginationData =
        responseData["hydra:totalItems"] > 30
          ? responseData["hydra:view"]
          : null;
      dispatch(setUserImoveisPaginationData(paginationData));
    }

    dispatch(setUserImoveis({ imoveis: userImoveis, count }));
    dispatch(setIsLoading(false));
  };
}

export function fetchUserFavoritos() {
  return async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const state = getState();

    const { user, token } = state.auth.authData;

    const response = await fetch(
      `${options.baseURL}/imovel_interessados?interessado.id=${user.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          //Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseData = await response.json();

    let userFavoritos = [];
    let count = 0;
    //console.log("FAVORITOS RESPONSE DATA", responseData);
    if ("hydra:member" in responseData) {
      //console.log("Setting imoveis: ", responseData);
      userFavoritos = responseData["hydra:member"].map(
        (favData) => new Favorite(favData)
      );
      count = responseData["hydra:totalItems"];
    }

    dispatch(setUserFavoritos({ favoritos: userFavoritos, count }));
    dispatch(setIsLoading(false));
  };
}

export const onSearch = createAsyncThunk(
  "imovel/onSearch",
  async (searchParams) => {
    //console.log("SEARCH PARAMS: ", searchParams);

    const baseURL = options.baseURL;

    const bairro = searchParams.bairro ? searchParams.bairro : "";
    const desc = searchParams.desc ? searchParams.desc : "";
    let url = `${baseURL}/public/imoveis?endereco.uf=${searchParams.uf}&endereco.cidade=${searchParams.cidade}&endereco.bairro=${bairro}&text=${desc}`;

    url += `&para_alugar=${searchParams.paraAlugar}`;
    url += `&imovel_comercial=${searchParams.imovelComercial}`;
    url +=
      searchParams.categoria === "todas"
        ? ""
        : `&categoria.id=${searchParams.categoria}`;

    url += "&order[id]=desc";
    url += "&page=" + searchParams.page;

    url += "&dormitorios[gte]=" + searchParams.dormitorios;
    url += "&banheiros[gte]=" + searchParams.banheiros;
    url += "&cozinhas[gte]=" + searchParams.cozinhas;
    url += "&garagens[gte]=" + searchParams.garagens;

    //console.log("SEARCH URL: ", url);
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const responseData = await response.json();

    let imoveis = [];
    let paginationData = null;
    if ("hydra:member" in responseData) {
      imoveis = responseData["hydra:member"].map(
        (imovelData) => new Imovel(imovelData)
      );
      paginationData =
        responseData["hydra:totalItems"] > 30
          ? responseData["hydra:view"]
          : null;

      //console.log("PAGINATION DATA: ", paginationData);
    }

    return { imoveis, paginationData };
  }
);

export const onSaveImovel = (imovelFormData) => {
  return async (dispatch, getState) => {
    const authState = getState().auth;
    const response = await fetch(`${options.baseURL}/imoveis`, {
      method: "POST",
      headers: {
        //"Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authState.authData.token}`,
      },
      body: imovelFormData,
    });

    //console.log("SAVE IMOVEL RESPONSE: ", response);
    let responseData = null;
    try {
      responseData = await response.json();
      //console.log("SAVE IMOVEL RESPONSE DATA: ", responseData);
    } catch (e) {
      //  console.log("Erro ao salvar Imovel");
    }
    //await response.json();
    if (response.status === 201) {
      //responseData = await response.json();
      //console.log("SAVE IMOVEL RESPONSE DATA", responseData);
      dispatch(onSaveImovelSuccess(responseData));
    } else {
      dispatch(
        onSaveImovelError({
          message: "Erro Desconhecido ao tentar salvar imóvel.",
        })
      );
    }
  };
};

export const onShowImovel = (imovelId) => {
  return async (dispatch, getState) => {
    //const authState = getState().auth;
    const imovelState = getState().imovel;
    const authState = getState().auth;
    const imoveis = imovelState.imoveis;
    const imovelIndex = imoveis.findIndex((imovel) => imovel.id === imovelId);

    if (imovelIndex !== -1) {
      dispatch(setDetailImovel(imoveis[imovelIndex]));
      return;
    } else {
      dispatch(setIsLoading(true));
      //console.log("Fetching data in the backend: ", imovelId);
      const headers = {
        "Content-Type": "application/json",
      };

      if (authState.authData.token) {
        headers.Authorization = `Bearer ${authState.authData.token}`;
      }

      const response = await fetch(
        `${options.baseURL}/public/imoveis/${imovelId}`,
        {
          headers,
        }
      );

      //console.log("FETCH SINGLE IMOVEL RESPONSE: ", response);

      if (response.status === 200) {
        const responseData = await response.json();
        //console.log("FETCH SINGLE IMOVEL RESPONSE DATA: ", responseData);
        const detailImovel = new Imovel(responseData);
        dispatch(setDetailImovel(detailImovel));
        dispatch(setIsLoading(false));
      } else {
        dispatch(setIsLoading(false));
        dispatch(setFetchError("Imovel não encontrado"));
      }
    }
  };
};

export const onEditImovel = (imovelId) => {
  return async (dispatch, getState) => {
    //const authState = getState().auth;
    const imovelState = getState().imovel;
    const imoveis = imovelState.userImoveis;
    const imovelIndex = imoveis.findIndex((imovel) => imovel.id === imovelId);

    //console.log("EDIT IMOVEL: ", imovelIndex);
    let editImovel = null;
    if (imovelIndex !== -1) {
      editImovel = imoveis[imovelIndex];

      //return;
    } else {
      dispatch(setIsLoading(true));
      //console.log("Fetching data in the backend: ", imovelId);

      const response = await fetch(
        `${options.baseURL}/public/imoveis/${imovelId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = await response.json();
      //console.log("FETCH SINGLE IMOVEL RESPONSE DATA: ", responseData);
      editImovel = new Imovel(responseData);
    }

    dispatch(setEditImovel(editImovel));
    dispatch(setEditImovelFotoPrincipal(editImovel.fotoPrincipal));
    dispatch(setEditImovelFotos(editImovel.fotos));

    dispatch(setIsLoading(false));
  };
};

export const onChangeFotoPrincipal = (imovelId, formData) => {
  return async (dispatch, getState) => {
    dispatch(setIsLoadingPhotos(true));
    const authState = getState().auth;
    const imovelState = getState().imovel;
    const response = await fetch(
      `${options.baseURL}/imoveis/${imovelId}/foto-principal`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authState.authData.token}`,
        },
        body: formData,
      }
    );

    // console.log("CHANGE PHOTO RESPONSE: ", response);

    if (response.status && response.status === 201) {
      const responseData = await response.json();
      dispatch(refreshEditImovelFotoPrincipal(responseData.fotoPrincipal));
      // dispatch(fetchUserImoveis());
    } else {
      dispatch(
        onSaveImovelError(
          "Não foi possível alterar a foto principal do imovel."
        )
      );
    }
    dispatch(setIsLoadingPhotos(false));
  };
};

export const onAddFoto = (imovelId, formData) => {
  return async (dispatch, getState) => {
    dispatch(setIsLoadingPhotos(true));
    const authState = getState().auth;
    // const imovelState = getState().imovel;
    const response = await fetch(
      `${options.baseURL}/imoveis/${imovelId}/fotos`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authState.authData.token}`,
        },
        body: formData,
      }
    );

    // console.log("CHANGE PHOTO RESPONSE: ", response);

    if (response.status && response.status === 201) {
      const responseData = await response.json();
      dispatch(refreshEditImovelFotos(responseData.fotos));
      // dispatch(fetchUserImoveis());
    } else {
      dispatch(
        onSaveImovelError(
          "Não foi possível alterar a foto principal do imovel."
        )
      );
    }

    dispatch(setIsLoadingPhotos(false));
  };
};

export const onRemoveFoto = (imovelId, fotoId) => {
  return async (dispatch, getState) => {
    dispatch(setIsLoadingPhotos(true));
    const authState = getState().auth;
    // const imovelState = getState().imovel;
    const response = await fetch(
      `${options.baseURL}/imoveis/${imovelId}/fotos/${fotoId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authState.authData.token}`,
        },
      }
    );

    // console.log("CHANGE PHOTO RESPONSE: ", response);

    if (response.status && response.status === 204) {
      //const responseData = await response.json();
      const fotosResponse = await fetch(
        `${options.baseURL}/imoveis/${imovelId}/fotos`,
        {
          headers: {
            Authorization: `Bearer ${authState.authData.token}`,
          },
        }
      );

      if (fotosResponse.status === 200) {
        const fotosResponseData = await fotosResponse.json();
        dispatch(refreshEditImovelFotos(fotosResponseData["hydra:member"]));
      }
      //dispatch(fetchUserImoveis());
    } else {
      dispatch(
        onSaveImovelError(
          "Não foi possível alterar a foto principal do imovel."
        )
      );
    }
    dispatch(setIsLoadingPhotos(false));
  };
};

export const onUpdateImovel = (imovelId, imovelData) => {
  return async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const authState = getState().auth;
    const response = await fetch(`${options.baseURL}/imoveis/${imovelId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authState.authData.token}`,
      },
      body: JSON.stringify(imovelData),
    });

    //console.log("UPDATE IMOVEL RESPONSE: ", response);
    let responseData = null;
    try {
      responseData = await response.json();
      //console.log("UPDATE IMOVEL RESPONSE DATA: ", responseData);
    } catch (e) {
      //console.log("Erro ao salvar Imovel");
    }
    //await response.json();
    if (response.status === 200) {
      //responseData = await response.json();
      //console.log("SAVE IMOVEL RESPONSE DATA", responseData);
      dispatch(onUpdateImovelSuccess(responseData));
    } else {
      dispatch(
        onSaveImovelError({
          message: "Erro Desconhecido ao tentar salvar imóvel.",
        })
      );
    }
    dispatch(setIsLoading(false));
  };
};

export const onRemoveImovel = (imovelId) => {
  return async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const authState = getState().auth;
    const response = await fetch(`${options.baseURL}/imoveis/${imovelId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authState.authData.token}`,
      },
    });

    if (response.status === 204) {
      dispatch(setRemoveSuccess(true));
    } else {
      dispatch(setRemoveSuccess(false));
      dispatch(setRemoveError("Não foi possível remover o imóvel"));
    }

    dispatch(setIsLoading(false));
  };
};

export const onAddFavorite = (imovelId) => {
  return async (dispatch, getState) => {
    const authState = getState().auth;
    // if (!authState.authData.token) {
    //   //dispatch(checkAuth());
    //   redirect("/login");
    //   return;
    // }
    const response = await fetch(`${options.baseURL}/imovel_interessados`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authState.authData.token}`,
      },
      body: JSON.stringify({
        imovel: "/api/v2/imoveis/" + imovelId,
      }),
    });

    //console.log("ADD FAVORITE RESPONSE: ", response);
    if (response.status === 201) {
      dispatch(setSaveStatus(true));
    } else {
      dispatch(setSaveError("Não foi possível adicionar o favorito."));
    }
  };
};

export const onRemoveFavorite = (favoriteId) => {
  return async (dispatch, getState) => {
    const authState = getState().auth;

    const response = await fetch(
      `${options.baseURL}/imovel_interessados/${favoriteId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authState.authData.token}`,
        },
      }
    );

    //console.log("REMOVE FAVORITE RESPONSE: ", response);
    if (response.status === 204) {
      dispatch(setOperationStatus(OPERATION_SUCCESS));
      dispatch(fetchUserFavoritos());
    } else {
      dispatch(setOperationStatus(OPERATION_FAILURE));
    }
  };
};

export const {
  startSearchRequest,
  setLoadedImoveis,
  updateSearchParams,
  setUserFavoritos,
  setUserImoveis,
  setIsLoading,
  setPaginationData,
  setUserImoveisPaginationData,
  onSaveImovelSuccess,
  onSaveImovelError,
  setSaveStatus,
  setSaveError,
  onUpdateImovelSuccess,
  setDetailImovel,
  setEditImovel,
  setFetchError,
  setEditImovelFotoPrincipal,
  setEditImovelFotos,
  refreshEditImovelFotos,
  refreshEditImovelFotoPrincipal,
  setIsLoadingPhotos,
  updateImovelInCache,
  checkAuth,
  setRemoveSuccess,
  setRemoveError,
  setOperationStatus,
} = imovelSlice.actions;

export const selectImovelStore = (state) => state.imovel;
export const selectImovelById = (state, imovelId) =>
  state.imovel.imoveis.find((imovel) => imovel.id === imovelId);

export const selectSearchParams = (state) => state.imovel.searchParams;

export default imovelSlice.reducer;
