import React, { Fragment } from "react";
export default class DashboardHeader extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">{this.props.title}</h1>
          <div className="btn-toolbar mb-2 mb-md-0">{this.props.children}</div>
        </div>
        <hr></hr>
      </Fragment>
    );
  }
}
