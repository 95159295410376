import React from "react";
import { Link } from "react-router-dom";
import CurrencyLabelComponent from "../ui/CurrencyLabelComponent";
import cssStyles from "./SearchResultItem.module.css";
import options from "../../global-options";
const SearchResultItem = (props) => {
  const { imovel, setModalImage, setModalTitle } = props;
  const imgUrl = `${options.publicURL}/${imovel.fotoPrincipal}`;
  const setModalImageHandler = () => {
    setModalImage(imgUrl);
    setModalTitle(imovel.titulo);
  };
  return (
    <React.Fragment>
      <div className="card mb-4">
        <div className="card-header">{imovel.titulo}</div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-4" style={{ overflow: "hidden" }}>
              <div className={cssStyles.itemPhoto}>
                <a
                  className={cssStyles["show-modal-link"]}
                  data-bs-toggle="modal"
                  tabIndex={-1}
                  data-bs-target="#resultPageModal"
                  onClick={setModalImageHandler}
                >
                  <img src={imgUrl}></img>
                </a>
              </div>
            </div>
            <div className="col-md-8 d-flex flex-column justify-content-between">
              <p className="card-text">{imovel.descricao}</p>
              <p className="card-text d-flex justify-content-between">
                <span>
                  <b>Anúncio: </b>
                  {imovel.paraAlugar ? "Aluguel" : "Venda"}
                </span>
                <span className="justify-self-start">
                  <b>Tipo: </b>
                  {imovel.categoria.nome}
                </span>
              </p>

              <p className="card-text d-flex justify-content-between">
                <span>
                  <b>Bairro: </b>
                  {imovel.endereco.bairro}
                </span>
                <span>
                  <b>Valor: </b>
                  <CurrencyLabelComponent value={imovel.valor} />
                </span>
              </p>

              <div className="text-end">
                <Link
                  to={"/imovel/" + imovel.id + "/detail"}
                  className="btn btn-primary"
                >
                  Detalhes
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SearchResultItem;
