export default class User {
  constructor(data) {
    this.id = data.id;
    this.nome = data.nome;
    this.cpf = data.cpf;
    this.telefone = data.telefone;
    this.email = data.email;
    this.username = data.username;
    this.roles = data.roles;
  }
}
