import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAuthStore, fetchUserProfile } from "../../../store/redux/auth";
const ProfilePage = () => {
  const dispatch = useDispatch();
  const authStore = useSelector(selectAuthStore);
  const { user } = authStore.authData;

  useEffect(() => {
    if (!user) {
      dispatch(fetchUserProfile());
    }
  }, [user, dispatch]);
  return (
    <div>
      <h1 className="mt-4">Perfil do Usuário</h1>
      <hr></hr>
      <div className="card">
        <div className="card-body">
          <ul className="list-group">
            <li className="list-group-item">
              <b>Nome: </b>
              {user?.nome}
            </li>
            <li className="list-group-item">
              <b>CPF: </b>
              {user?.cpf}
            </li>
            <li className="list-group-item">
              <b>Nome de Usuário: </b>
              {user?.username}
            </li>
            <li className="list-group-item">
              <b>E-mail: </b>
              {user?.email}
            </li>
            <li className="list-group-item">
              <b>Telefone: </b>
              {user?.telefone}
            </li>
          </ul>
        </div>
        <div className="card-footer">
          <div className="text-end">
            <button className="btn btn-primary">Editar</button>
            <button className="btn btn-warning ms-2">Alterar Senha</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
