import { Fragment, useEffect, useState } from "react";

const PaginatorComponent = (props) => {
  const [currentPage, setCurrentPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);

  const { paginationData } = props;

  const parsePaginationData = () => {
    setCurrentPage(extractPageNumber(paginationData["@id"]));
    setFirstPage(extractPageNumber(paginationData["hydra:first"]));
    setNextPage(extractPageNumber(paginationData["hydra:next"]));
    setPreviousPage(extractPageNumber(paginationData["hydra:previous"]));
    setLastPage(extractPageNumber(paginationData["hydra:last"]));
  };

  const extractPageNumber = (pageData) => {
    if (!pageData) {
      return null;
    }
    //console.log("PAGE DATA: ", pageData);
    const index = pageData.indexOf("page=");

    //console.log("INDEX: ", index);
    const pageNumber = pageData.substring(index + 5);

    //console.log("CURRENT PAGE: ", currentPage);

    return pageNumber;
  };

  useEffect(() => {
    parsePaginationData();
  });

  const onLoadPageHandler = (pageData) => {
    props.loadPage(pageData);
  };

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination mt-2">
        {currentPage !== firstPage && (
          <li className="page-item">
            <a
              className="page-link anchor-button"
              aria-label="Previous"
              onClick={onLoadPageHandler.bind(this, previousPage)}
            >
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
        )}
        <li className="page-item active">
          <span className="page-link ">{currentPage}</span>
        </li>

        <li className="page-item">
          <span className="page-link">de {lastPage}</span>
        </li>

        {currentPage !== lastPage && (
          <li className="page-item">
            <a
              className="page-link anchor-button"
              aria-label="Next"
              onClick={onLoadPageHandler.bind(this, nextPage)}
            >
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default PaginatorComponent;
