import { Link } from "react-router-dom";
import styles from "./SidebarComponent.module.css";
const SidebarComponent = () => {
  return (
    <nav
      id="sidebarMenu"
      className={`col-md-3 col-lg-2 d-md-block bg-light ${styles["sidebar"]}  collapse`}
    >
      <div className="position-sticky pt-3">
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link
              className={styles["sidebar-nav-link"] + " nav-link active"}
              aria-current="page"
              to="/dashboard"
            >
              <i className="bi bi-house pe-2"></i>
              Dashboard
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={styles["sidebar-nav-link"] + " nav-link"}
              to="/dashboard/perfil"
            >
              <i className="bi bi-person-badge pe-2"></i>
              Meu Perfil
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={styles["sidebar-nav-link"] + " nav-link"}
              to="/dashboard/meus-imoveis"
            >
              <i className="bi bi-building pe-2"></i>
              Meus Imóveis
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={styles["sidebar-nav-link"] + " nav-link"}
              to="/dashboard/favoritos"
            >
              <i className="bi bi-star pe-2"></i>
              Favoritos
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default SidebarComponent;
