import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

import OverlaySpinner from "../../components/ui/OverlaySpinner";
import { selectAuthStore, login, clearAuthError } from "../../store/redux/auth";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const authStore = useSelector(selectAuthStore);

  const navigate = useNavigate();
  const isLoading = authStore.isLoading;

  useEffect(() => {
    if (authStore.loginError) {
      toast.error(authStore.loginError);
      dispatch(clearAuthError());
    }

    if (authStore.authData.token) {
      navigate("/dashboard");
    }
  }, [authStore.loginError, authStore.authData.token, dispatch, navigate]);

  useEffect(() => {}, [authStore.isLoading]);

  function onSubmitHandler(event) {
    event.preventDefault();
    dispatch(login(username, password));
  }

  const onUsernameChangeHandler = (event) => {
    setUsername(event.target.value);
  };

  const onPasswordChangeHandler = (event) => {
    setPassword(event.target.value);
  };
  return (
    <div className="wrapper">
      {isLoading && <OverlaySpinner />}
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 mt-4">
            <ToastContainer />
            <div className="card">
              <div className="card-header">
                <h1 className="h5 card-title">SF - Imobiliária</h1>
              </div>
              <div className="card-body">
                <form onSubmit={onSubmitHandler}>
                  <div className="mb-3">
                    <label htmlFor="username" className="form-label">
                      Nome de Usuário ou E-mail
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      onChange={onUsernameChangeHandler}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      onChange={onPasswordChangeHandler}
                    />
                  </div>

                  <button type="submit" className="btn btn-primary">
                    Entrar
                  </button>
                  <Link to="/" className="ms-2 btn btn-secondary">
                    Cancelar
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
