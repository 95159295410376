import {
  useReducer,
  useContext,
  Fragment,
  useState,
  useRef,
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import MapInputComponent from "../../../components/ui/MapInputComponent";
import GeneralContext from "../../../store/general";
import DashboardHeader from "../components/DashboardHeader";
import {
  onSaveImovel,
  selectImovelStore,
  setSaveError,
  setSaveStatus,
} from "../../../store/redux/imoveis";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// const initialState = {

//   formData: null,
// };
const formReducer = (state, action) => {
  const newFormData = new FormData();

  for (const formParam of state.formData) {
    newFormData.append(formParam[0], formParam[1]);
    //console.log("Old value: ", formParam);
  }

  //console.log("FORM PAYLOAD FIELD: ", action.payload.field);
  if (action.payload.field !== "fotos[]") {
    newFormData.set(action.payload.field, action.payload.value);
  } else {
    newFormData.delete("fotos[]");

    const files = action.payload.value;

    for (const file of files) {
      newFormData.append("fotos[]", file, file.name);
    }
  }

  return { formData: newFormData };
};

const NewImovel = () => {
  const [formState, formDispatch] = useReducer(formReducer, null, () => {
    const formData = new FormData();

    formData.append("disponivel", true);
    formData.append("titulo", "");
    formData.append("descricao", "");
    formData.append("dormitorios", 0);
    formData.append("banheiros", 0);
    formData.append("cozinhas", 0);
    formData.append("garagens", 0);
    formData.append("area_construida", 0);
    formData.append("area_total", 0);
    formData.append("valor", 0);
    formData.append("categoria_id", null);
    formData.append("imovel_comercial", 0);
    formData.append("para_alugar", 0);
    formData.append("logradouro", "");
    formData.append("numero", "");
    formData.append("referencia", "");
    formData.append("cep", "");
    formData.append("bairro", "");
    formData.append("cidade", "");
    formData.append("uf", "");
    formData.append("latitude", null);
    formData.append("longitude", null);
    formData.append("fotos[]", null);
    formData.append("foto_principal", null);

    return { formData };
  });

  const disponivelCheckboxRef = useRef(null);
  const multiFileFieldRef = useRef(null);

  // const cidadeSelectRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const generalCxt = useContext(GeneralContext);

  const imovelStore = useSelector(selectImovelStore);

  useEffect(() => {
    generalCxt.fetchAndSetCategorias();
  }, []);

  useEffect(() => {
    if (disponivelCheckboxRef) {
      const isDisponivel = formState.formData.get("disponivel");
      disponivelCheckboxRef.current.checked = isDisponivel;
    }
  }, [disponivelCheckboxRef, formState.formData]);

  useEffect(() => {
    if (imovelStore.saveSuccess) {
      toast.success("Imovel salvo com sucesso!");
      setTimeout(() => {
        navigate("/dashboard/meus-imoveis");
      }, 3000);
    }
    if (imovelStore.saveError) {
      toast.error(imovelStore.saveError);
      dispatch(setSaveError(null));
    }
  }, [imovelStore.saveSuccess, imovelStore.saveError]);
  const validExtensions = ["jpg", "jpeg", "png"];

  const highlightInvalid = (event) => {
    const inputEl = event.target;
    if (!inputEl.checkValidity()) {
      inputEl.classList.add("invalid-input");
    } else {
      inputEl.classList.remove("invalid-input");
    }
  };

  const onFormChangeHandler = (field, event) => {
    formDispatch({ payload: { field, value: event.target.value } });
  };

  const onCheckboxChangeHandler = (field, event) => {
    //console.log("Checkbox: ", event.target.checked);

    formDispatch({ payload: { field, value: event.target.checked ? 1 : 0 } });
  };

  const checkFileValidity = (file) => {
    if (!file) {
      return false;
    }
    const newFileName = file.name;

    const lastDotIndex = newFileName.lastIndexOf(".");
    const fileExtension = newFileName.substring(lastDotIndex + 1);

    if (!validExtensions.includes(fileExtension)) {
      toast.error(
        "Só são permitidos arquivos de imagem com as extensões: .jpg, .jpeg, .png"
      );
      return false;
    }

    return true;
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();

    for (const v of formState.formData) {
      console.log("Form Value: ", v);
    }
    dispatch(onSaveImovel(formState.formData));
  };

  const onSelectFileHandler = (event) => {
    const files = event.target.files;
    //console.log("onSelectFileHandler: ", files);

    if (files) {
      if (!checkFileValidity(files[0])) {
        return;
      }

      formDispatch({ payload: { field: "foto_principal", value: files[0] } });
    }
  };

  const onMultiSelectFilesHandler = (event) => {
    const files = event.target.files;
    //console.log("onMultiSelectFilesHandler: ", files);

    if (files) {
      if (files.length > 5) {
        toast.error("Só é possível enviar até 5 arquivos.");
        multiFileFieldRef.current.setCustomValidity(
          "Só é possível enviar até 5 arquivos."
        );
      } else {
        multiFileFieldRef.current.setCustomValidity("");
      }
      for (let i = 0; i < files.length; i++) {
        if (!checkFileValidity(files[i])) {
          return;
        }
      }

      formDispatch({ payload: { field: "fotos[]", value: files } });
    }
  };

  const onUFSelectChangeHandler = async (event) => {
    const selectedUF = event.target.value;

    if (generalCxt.ufs.includes(selectedUF)) {
      generalCxt.onSearchCidades(selectedUF);
      formDispatch({ payload: { field: "uf", value: selectedUF } });
    }
  };

  const onCidadeSelectChangeHandler = (event) => {
    formDispatch({ payload: { field: "cidade", value: event.target.value } });
  };

  const onGetPositionHandler = (position) => {
    //console.log("POSITION: ", position);
    formDispatch({ payload: { field: "latitude", value: position.lat } });
    formDispatch({ payload: { field: "longitude", value: position.lng } });
  };

  return (
    <Fragment>
      <DashboardHeader title="Novo Imóvel"></DashboardHeader>

      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8 my-4">
          <ToastContainer />
          <div className="card">
            <div className="card-body">
              <form onSubmit={onSubmitHandler}>
                <div className="form-check py-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="disponivel"
                    onChange={onCheckboxChangeHandler.bind(this, "disponivel")}
                    ref={disponivelCheckboxRef}
                  />
                  <label className="form-check-label" htmlFor="disponivel">
                    Publicar anúncio
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="titulo" className="form-label">
                    Titulo *
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="titulo"
                    onChange={onFormChangeHandler.bind(this, "titulo")}
                    onBlur={highlightInvalid}
                    required
                    minLength={15}
                    maxLength={64}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Este campo não pode ficar em branco."
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="descricao" className="form-label">
                    Descrição *
                  </label>
                  <textarea
                    className="form-control"
                    onChange={onFormChangeHandler.bind(this, "descricao")}
                    onBlur={highlightInvalid}
                    minLength={30}
                    maxLength={300}
                    required
                  ></textarea>
                </div>
                <div className="mb-3">
                  <label htmlFor="telefone" className="form-label">
                    Foto Principal *
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="telefone"
                    onChange={onSelectFileHandler}
                    onBlur={highlightInvalid}
                    required
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Este campo não pode ficar em branco."
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="fotos" className="form-label">
                    Outras Fotos (Até 5)
                  </label>
                  <input
                    type="file"
                    multiple
                    className="form-control"
                    id="fotos"
                    onChange={onMultiSelectFilesHandler}
                    onBlur={highlightInvalid}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Outras fotos do imóvel."
                    ref={multiFileFieldRef}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="dormitorios" className="form-label">
                        Dormitorios
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="dormitorios"
                        onChange={onFormChangeHandler.bind(this, "dormitorios")}
                        onBlur={highlightInvalid}
                        required
                        min={0}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Este campo não pode ficar em branco."
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="banheiros" className="form-label">
                        Banheiros
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="banheiros"
                        onChange={onFormChangeHandler.bind(this, "banheiros")}
                        onBlur={highlightInvalid}
                        required
                        min={0}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Este campo não pode ficar em branco."
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="cozinhas" className="form-label">
                        Cozinhas
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="cozinhas"
                        onChange={onFormChangeHandler.bind(this, "cozinhas")}
                        onBlur={highlightInvalid}
                        required
                        min={0}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Este campo não pode ficar em branco."
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="garagens" className="form-label">
                        Garagens
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="garagens"
                        onChange={onFormChangeHandler.bind(this, "garagens")}
                        onBlur={highlightInvalid}
                        required
                        min={0}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Este campo não pode ficar em branco."
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="area_construida" className="form-label">
                    Área Construída
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      id="area_construida"
                      onChange={onFormChangeHandler.bind(
                        this,
                        "area_construida"
                      )}
                      onBlur={highlightInvalid}
                      required
                      min={0}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Este campo não pode ficar em branco"
                      aria-describedby="input-area-total"
                    />
                    <span className="input-group-text" id="input-area-total">
                      m<sup>2</sup>
                    </span>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="area_total" className="form-label">
                    Área Total
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      id="area_total"
                      onChange={onFormChangeHandler.bind(this, "area_total")}
                      onBlur={highlightInvalid}
                      required
                      min={1}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Este campo não pode ficar em branco"
                      aria-describedby="input-area-total"
                    />
                    <span className="input-group-text" id="input-area-total">
                      m<sup>2</sup>
                    </span>
                  </div>
                </div>
                <div className="form-check py-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="imovel_comercial"
                    onChange={onCheckboxChangeHandler.bind(
                      this,
                      "imovel_comercial"
                    )}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="imovel_comercial"
                  >
                    Imóvel Comercial
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="confirmacaoSenha" className="form-label">
                    Categoria
                  </label>
                  <select
                    className="form-control"
                    onChange={onFormChangeHandler.bind(this, "categoria_id")}
                    required
                  >
                    <option value="">Selecionar...</option>
                    {generalCxt.categorias.map((cat) => (
                      <option key={cat.id} value={cat.id}>
                        {cat.nome}
                      </option>
                    ))}
                  </select>
                </div>
                <h2 className="py-2 h4">Endereço</h2>
                <div className="mb-3">
                  <label htmlFor="logradouro" className="form-label">
                    Logradouro *
                  </label>
                  <textarea
                    className="form-control"
                    onChange={onFormChangeHandler.bind(this, "logradouro")}
                    onBlur={highlightInvalid}
                    minLength={3}
                    maxLength={300}
                  ></textarea>
                </div>
                <div className="mb-3">
                  <label htmlFor="logradouro" className="form-label">
                    Número *
                  </label>
                  <input
                    className="form-control"
                    onChange={onFormChangeHandler.bind(this, "numero")}
                    onBlur={highlightInvalid}
                    minLength={1}
                    maxLength={80}
                    required
                  ></input>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <div className="mb-3">
                      <label htmlFor="confirmacaoSenha" className="form-label">
                        UF
                      </label>
                      <select
                        className="form-control"
                        onChange={onUFSelectChangeHandler}
                        required
                      >
                        <option value="">UF</option>
                        {generalCxt.ufs.map((uf) => (
                          <option key={uf} value={uf}>
                            {uf}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="mb-3">
                      <label htmlFor="confirmacaoSenha" className="form-label">
                        Cidade
                      </label>
                      <select
                        required
                        className="form-control"
                        onChange={onCidadeSelectChangeHandler}
                      >
                        <option value="">Cidade</option>
                        {generalCxt.cidades.map((cidade) => (
                          <option key={cidade.id} value={cidade.nome}>
                            {cidade.nome}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Bairro</label>
                      <input
                        className="form-control"
                        onChange={onFormChangeHandler.bind(this, "bairro")}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">CEP</label>
                      <input
                        className="form-control"
                        type="number"
                        onChange={onFormChangeHandler.bind(this, "cep")}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-check py-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="para_alugar"
                    onChange={onCheckboxChangeHandler.bind(this, "para_alugar")}
                  />
                  <label className="form-check-label" htmlFor="para_alugar">
                    Imóvel Para Alugar
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="logradouro" className="form-label">
                    Valor *
                  </label>
                  <div className="input-group">
                    <span className="input-group-text" id="input-area-total">
                      R$
                    </span>
                    <input
                      className="form-control"
                      onChange={onFormChangeHandler.bind(this, "valor")}
                      onBlur={highlightInvalid}
                      minLength={2}
                      maxLength={80}
                      type="number"
                      required
                    ></input>
                  </div>
                </div>
                <div>
                  <MapInputComponent getPosition={onGetPositionHandler} />
                </div>

                <button type="submit" className="btn btn-primary">
                  Enviar
                </button>
                <Link to="/dashboard" className="ms-2 btn btn-secondary">
                  Cancelar
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NewImovel;
