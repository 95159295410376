import { Fragment, useContext, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import MapInputComponent from "../../../components/ui/MapInputComponent";
import DashboardHeader from "../components/DashboardHeader";
import GeneralContext from "../../../store/general";
import {
  onAddFoto,
  onChangeFotoPrincipal,
  onEditImovel,
  onRemoveFoto,
  onRemoveImovel,
  onSaveImovel,
  onUpdateImovel,
  selectImovelById,
  selectImovelStore,
  setRemoveError,
  setRemoveSuccess,
  setSaveError,
  setSaveStatus,
} from "../../../store/redux/imoveis";
import { useEffect } from "react";
import { useRef } from "react";
import OverlaySpinner from "../../../components/ui/OverlaySpinner";
import PhotoItem from "../components/PhotoItem";
import { useState } from "react";
import SpinnerComponent from "../../../components/ui/SpinnerComponent";
import OpenModalButton from "../../../components/ui/OpenModalButton";
import ModalComponent from "../../../components/ui/ModalComponent";
import options from "../../../global-options";
const IMOVEL = "IMOVEL";
const ENDERECO = "ENDERECO";
const DEFINE_INITIAL_STATE = "DEFINE_INITIAL_STATE";

const formReducer = (state, action) => {
  switch (action.type) {
    case IMOVEL:
      return { ...state, [action.payload.field]: action.payload.value };
    case ENDERECO:
      console.log("UPDATING ADDRESS: ", action.payload);
      return {
        ...state,
        endereco: {
          ...state.endereco,
          [action.payload.field]: action.payload.value,
        },
      };
    case DEFINE_INITIAL_STATE: {
      return { ...action.payload };
    }
    default:
      return state;
  }
};

const initialState = {
  disponivel: false,
  titulo: "",
  descricao: "",
  dormitorios: 0,
  banheiros: 0,
  cozinhas: 0,
  garagens: 0,
  area_construida: 0,
  area_total: 0,
  categoria: null,
  imovel_comercial: false,
  para_alugar: false,
  valor: 0,
  slug: "",
  createdAt: "",
  endereco: {
    logradouro: "",
    cep: null,
    bairro: "",
    cidade: "",
    uf: "",
    latitude: 0,
    longitude: 0,
    numero: null,
    referencia: "",
  },
  fotoPrincipal: null,
  fotos: [],
};

const EditImovel = (props) => {
  const { imovelId } = useParams();
  const imovelStore = useSelector(selectImovelStore);
  const imovel = imovelStore.editImovel; //useSelector((state) => selectImovelById(state, imovelId));
  const fotoPrincipal = imovelStore.editImovelFotoPrincipal;
  const fotos = imovelStore.editImovelFotos;
  const [formState, formDispatch] = useReducer(formReducer, initialState);

  const generalCxt = useContext(GeneralContext);

  const disponivelCheckboxRef = useRef(null);
  const fotoPrincipalInputRef = useRef(null);
  const fotoInputRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFormChangeHandler = (type, field, event) => {
    // console.log("DISPATCHING FORM EVENT");
    formDispatch({ type, payload: { field, value: event.target.value } });
  };

  // const onFormChangeHandler = (event) => {
  //   console.log("DISPATCHING FORM EVENT");
  //   //formDispatch({ type, payload: { field, value: event.target.value } });
  // };

  const onCheckboxChangeHandler = (type, field, event) => {
    //console.log("Checkbox: ", event.target.checked);

    formDispatch({
      type,
      payload: { field, value: event.target.checked ? 1 : 0 },
    });
  };

  const highlightInvalid = (event) => {
    const inputEl = event.target;
    if (!inputEl.checkValidity()) {
      inputEl.classList.add("invalid-input");
    } else {
      inputEl.classList.remove("invalid-input");
    }
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    console.log(formState);
    dispatch(
      onUpdateImovel(imovel.id, {
        ...formState,
        categoria: "/api/v2/categorias/" + formState.categoria.id,
      })
    );
  };

  const onUFSelectChangeHandler = async (event) => {
    const selectedUF = event.target.value;

    if (generalCxt.ufs.includes(selectedUF)) {
      generalCxt.onSearchCidades(selectedUF);
      formDispatch({
        type: ENDERECO,
        payload: { field: "uf", value: selectedUF },
      });
    }
  };

  const onGetPositionHandler = (position) => {
    //console.log("POSITION: ", position);
    formDispatch({
      type: ENDERECO,
      payload: { field: "latitude", value: position.lat },
    });
    formDispatch({
      type: ENDERECO,
      payload: { field: "longitude", value: position.lng },
    });
  };

  const onUpdateFeaturedPhotoHandler = (event) => {
    const files = event.target.files;

    if (files) {
      const formData = new FormData();
      formData.append("foto_principal", files[0]);
      dispatch(onChangeFotoPrincipal(imovel.id, formData));
    }
  };

  const onAddPhotoHandler = (event) => {
    const files = event.target.files;

    if (files) {
      const formData = new FormData();
      formData.append("foto", files[0]);
      dispatch(onAddFoto(imovel.id, formData));
    }
  };
  const onRemovePhotoHandler = (fotoId) => {
    //console.log("REMOVING FOTO: ", fotoId);
    dispatch(onRemoveFoto(imovel.id, fotoId));
  };

  const onRemoveImovelHandler = () => {
    dispatch(onRemoveImovel(imovel.id));
  };
  useEffect(() => {
    generalCxt.fetchAndSetCategorias();
  }, []);

  useEffect(() => {
    if (imovelStore.saveSuccess) {
      toast.success("Imovel atualizado com sucesso!");
      dispatch(setSaveStatus(false));
      setTimeout(() => {
        navigate("/dashboard/meus-imoveis");
      }, 5000);
    }
    if (imovelStore.saveError) {
      toast.error(imovelStore.saveError);
      dispatch(setSaveError(null));
    }
  }, [imovelStore.saveSuccess, imovelStore.saveError]);

  useEffect(() => {
    //console.log("IMOVEL ID: ", imovelId);
    dispatch(onEditImovel(imovelId));
  }, [imovelId]);

  useEffect(() => {
    // console.log("EDIT IMOVEL: ", imovel);
    if (imovel) {
      formDispatch({
        type: DEFINE_INITIAL_STATE,
        payload: {
          disponivel: imovel.disponivel,
          titulo: imovel.titulo,
          descricao: imovel.descricao,
          dormitorios: imovel.dormitorios,
          banheiros: imovel.banheiros,
          cozinhas: imovel.cozinhas,
          garagens: imovel.garagens,
          area_construida: imovel.areaConstruida,
          area_total: imovel.areaTotal,
          categoria: imovel.categoria,
          imovel_comercial: imovel.imovelComercial,
          para_alugar: imovel.paraAlugar,
          valor: imovel.valor,
          slug: imovel.slug,
          createdAt: imovel.createdAt,
          endereco: {
            logradouro: imovel.endereco.logradouro,
            cep: imovel.endereco.cep,
            bairro: imovel.endereco.bairro,
            cidade: imovel.endereco.cidade,
            uf: imovel.endereco.uf,
            latitude: imovel.endereco.latitude
              ? imovel.endereco.latitude
              : null,
            longitude: imovel.endereco.longitude
              ? imovel.endereco.longitude
              : null,
            numero: imovel.endereco.numero,
            referencia: imovel.endereco.referencia,
          },
        },
      });
    }
  }, [imovel]);

  useEffect(() => {
    //console.log("Rendering fotos", fotoPrincipal);
  }, [fotoPrincipal, fotos]);
  useEffect(() => {
    //console.log("FORM STATE: ", formState);
  }, [formState]);

  useEffect(() => {
    if (imovelStore.removeSuccess) {
      toast.success("O imovel foi removido com sucesso!");
      setTimeout(() => {
        dispatch(setRemoveSuccess(false));
        navigate("/dashboard/meus-imoveis");
      }, 3000);
    }

    if (imovelStore.removeError) {
      toast.error(imovelStore.removeError);
      dispatch(setRemoveError(null));
    }
  }, [imovelStore.removeSuccess, imovelStore.removeError]);

  return (
    <Fragment>
      {(imovelStore.isLoading || !imovel) && <OverlaySpinner />}
      <DashboardHeader title="Editar Imóvel"></DashboardHeader>

      {!imovelStore.isLoading && (
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8 my-4">
            <ToastContainer />
            <div className="card">
              <div className="card-body">
                <form onSubmit={onSubmitHandler}>
                  <div className="mb-3">
                    <label className="form-label">
                      Alterar Foto Principal *
                    </label>
                    <input
                      type="file"
                      className="form-control mb-2"
                      id="telefone"
                      onChange={onUpdateFeaturedPhotoHandler}
                      onBlur={highlightInvalid}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                    />
                    {imovelStore.isLoadingPhotos && (
                      <SpinnerComponent theme="lds-blue" />
                    )}
                    {fotoPrincipal && !imovelStore.isLoadingPhotos && (
                      <img
                        className="img-fluid"
                        src={options.publicURL + "/" + fotoPrincipal}
                      ></img>
                    )}
                  </div>
                  <div className="form-check py-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={formState.disponivel ? true : false}
                      id="disponivel"
                      onChange={onCheckboxChangeHandler.bind(
                        this,
                        IMOVEL,
                        "disponivel"
                      )}
                      ref={disponivelCheckboxRef}
                    />
                    <label className="form-check-label" htmlFor="disponivel">
                      Publicar anúncio
                    </label>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="titulo" className="form-label">
                      Titulo *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="titulo"
                      name="titulo"
                      value={formState.titulo}
                      onChange={onFormChangeHandler.bind(
                        this,
                        IMOVEL,
                        "titulo"
                      )}
                      onBlur={highlightInvalid}
                      required
                      minLength={15}
                      maxLength={255}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Este campo não pode ficar em branco."
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="descricao" className="form-label">
                      Descrição *
                    </label>
                    <textarea
                      className="form-control"
                      onChange={onFormChangeHandler.bind(
                        this,
                        IMOVEL,
                        "descricao"
                      )}
                      onBlur={highlightInvalid}
                      minLength={30}
                      maxLength={300}
                      required
                      value={formState.descricao}
                    ></textarea>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="dormitorios" className="form-label">
                          Dormitorios
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="dormitorios"
                          onChange={onFormChangeHandler.bind(
                            this,
                            IMOVEL,
                            "dormitorios"
                          )}
                          onBlur={highlightInvalid}
                          required
                          min={0}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Este campo não pode ficar em branco."
                          value={formState.dormitorios}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="banheiros" className="form-label">
                          Banheiros
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="banheiros"
                          onChange={onFormChangeHandler.bind(
                            this,
                            IMOVEL,
                            "banheiros"
                          )}
                          onBlur={highlightInvalid}
                          required
                          min={0}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Este campo não pode ficar em branco."
                          value={formState.banheiros}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="cozinhas" className="form-label">
                          Cozinhas
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="cozinhas"
                          onChange={onFormChangeHandler.bind(
                            this,
                            IMOVEL,
                            "cozinhas"
                          )}
                          onBlur={highlightInvalid}
                          required
                          min={0}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Este campo não pode ficar em branco."
                          value={formState.cozinhas}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="garagens" className="form-label">
                          Garagens
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="garagens"
                          onChange={onFormChangeHandler.bind(
                            this,
                            IMOVEL,
                            "garagens"
                          )}
                          onBlur={highlightInvalid}
                          required
                          min={0}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Este campo não pode ficar em branco."
                          value={formState.garagens}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="area_construida" className="form-label">
                      Área Construída
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="area_construida"
                        onChange={onFormChangeHandler.bind(
                          this,
                          IMOVEL,
                          "area_construida"
                        )}
                        onBlur={highlightInvalid}
                        required
                        min={0}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Este campo não pode ficar em branco"
                        aria-describedby="input-area-total"
                        value={formState.area_construida}
                      />
                      <span className="input-group-text" id="input-area-total">
                        m<sup>2</sup>
                      </span>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="area_total" className="form-label">
                      Área Total
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="area_total"
                        onChange={onFormChangeHandler.bind(
                          this,
                          IMOVEL,
                          "area_total"
                        )}
                        onBlur={highlightInvalid}
                        required
                        min={1}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Este campo não pode ficar em branco"
                        aria-describedby="input-area-total"
                        value={formState.area_total}
                      />
                      <span className="input-group-text" id="input-area-total">
                        m<sup>2</sup>
                      </span>
                    </div>
                  </div>
                  <div className="form-check py-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={formState.imovelComercial ? true : false}
                      id="imovel_comercial"
                      onChange={onCheckboxChangeHandler.bind(
                        this,
                        IMOVEL,
                        "imovel_comercial"
                      )}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="imovel_comercial"
                    >
                      Imóvel Comercial
                    </label>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="confirmacaoSenha" className="form-label">
                      Categoria
                    </label>
                    <select
                      className="form-control"
                      onChange={onFormChangeHandler.bind(
                        this,
                        IMOVEL,
                        "categoria_id"
                      )}
                      required
                    >
                      <option value={formState.categoria?.id}>
                        {formState.categoria?.nome}
                      </option>
                      {generalCxt.categorias.map((cat) => (
                        <option key={cat.id} value={cat.id}>
                          {cat.nome}
                        </option>
                      ))}
                    </select>
                  </div>
                  <h2 className="py-2 h4">Endereço</h2>
                  <div className="mb-3">
                    <label htmlFor="logradouro" className="form-label">
                      Logradouro *
                    </label>
                    <textarea
                      className="form-control"
                      onChange={onFormChangeHandler.bind(
                        this,
                        ENDERECO,
                        "logradouro"
                      )}
                      onBlur={highlightInvalid}
                      minLength={3}
                      maxLength={300}
                      value={formState.endereco.logradouro}
                    ></textarea>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="logradouro" className="form-label">
                      Número *
                    </label>
                    <input
                      className="form-control"
                      onChange={onFormChangeHandler.bind(
                        this,
                        ENDERECO,
                        "numero"
                      )}
                      onBlur={highlightInvalid}
                      minLength={1}
                      maxLength={80}
                      required
                      value={formState.endereco.numero}
                    ></input>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <div className="mb-3">
                        <label
                          htmlFor="confirmacaoSenha"
                          className="form-label"
                        >
                          UF
                        </label>
                        <select
                          className="form-control"
                          onChange={onUFSelectChangeHandler}
                          required
                        >
                          <option value={formState.endereco.uf}>
                            {formState.endereco.uf}
                          </option>
                          {generalCxt.ufs.map((uf) => (
                            <option key={uf} value={uf}>
                              {uf}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="mb-3">
                        <label
                          htmlFor="confirmacaoSenha"
                          className="form-label"
                        >
                          Cidade
                        </label>
                        <select
                          required
                          className="form-control"
                          onChange={onFormChangeHandler.bind(
                            this,
                            ENDERECO,
                            "cidade"
                          )}
                        >
                          <option value={formState.endereco.cidade}>
                            {formState.endereco.cidade}
                          </option>
                          {generalCxt.cidades.map((cidade) => (
                            <option key={cidade.id} value={cidade.nome}>
                              {cidade.nome}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Bairro</label>
                        <input
                          className="form-control"
                          onChange={onFormChangeHandler.bind(
                            this,
                            ENDERECO,
                            "bairro"
                          )}
                          required
                          value={formState.endereco.bairro}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">CEP</label>
                        <input
                          className="form-control"
                          type="number"
                          onChange={onFormChangeHandler.bind(
                            this,
                            ENDERECO,
                            "cep"
                          )}
                          value={formState.endereco.cep}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-check py-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={formState.para_alugar ? true : false}
                      id="para_alugar"
                      onChange={onCheckboxChangeHandler.bind(
                        this,
                        IMOVEL,
                        "para_alugar"
                      )}
                    />
                    <label className="form-check-label" htmlFor="para_alugar">
                      Imóvel Para Alugar
                    </label>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="logradouro" className="form-label">
                      Valor *
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="input-area-total">
                        R$
                      </span>
                      <input
                        className="form-control"
                        onChange={onFormChangeHandler.bind(this, "valor")}
                        onBlur={highlightInvalid}
                        minLength={2}
                        maxLength={80}
                        type="number"
                        required
                        value={formState.valor}
                      ></input>
                    </div>
                  </div>
                  <div>
                    <MapInputComponent
                      lat={formState.endereco.latitude}
                      lng={formState.endereco.longitude}
                      getPosition={onGetPositionHandler}
                    />
                  </div>
                  {imovelStore.isLoadingPhotos && (
                    <SpinnerComponent theme="lds-blue" />
                  )}
                  {!imovelStore.isLoadingPhotos && (
                    <div className="row">
                      {fotos.map((foto) => (
                        <PhotoItem
                          key={foto.id}
                          foto={foto}
                          action={onRemovePhotoHandler}
                        />
                      ))}
                    </div>
                  )}

                  {(!fotos || fotos.length < 5) && (
                    <div className="mb-3">
                      <label htmlFor="fotos" className="form-label">
                        Adicionar Foto
                      </label>
                      <input
                        type="file"
                        multiple
                        className="form-control"
                        id="fotos"
                        onChange={onAddPhotoHandler}
                        onBlur={highlightInvalid}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Outras fotos do imóvel."
                        ref={fotoInputRef}
                      />
                    </div>
                  )}

                  <div className="d-flex justify-content-between">
                    <div>
                      <button type="submit" className="btn btn-primary">
                        Alterar
                      </button>
                      <Link to="/dashboard" className="ms-2 btn btn-secondary">
                        Cancelar
                      </Link>
                    </div>
                    <div>
                      <OpenModalButton
                        modalId="exampleModal"
                        classes="btn-danger"
                      >
                        Remover Imóvel
                      </OpenModalButton>
                      <ModalComponent
                        title="Confirmação de exclusão"
                        actions={
                          <Fragment>
                            <button
                              data-bs-dismiss="modal"
                              className="btn btn-secondary"
                            >
                              Não
                            </button>
                            <button
                              data-bs-dismiss="modal"
                              className="btn btn-danger ms-2"
                              onClick={onRemoveImovelHandler}
                            >
                              Sim
                            </button>
                          </Fragment>
                        }
                      >
                        Deseja realmente remover este imóvel?
                      </ModalComponent>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default EditImovel;
