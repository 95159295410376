import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, Router, RouterProvider } from "react-router-dom";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider } from "react-redux";
//import thunk from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
//import bootstrap from "bootstrap";
import router from "./router";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";

import reportWebVitals from "./reportWebVitals";

import { GeneralContextProvider } from "./store/general";
//import rootReducer from "./store/redux";
import imovelReducer from "./store/redux/imoveis";
import authReducer from "./store/redux/auth";

import { AuthContextProvider } from "./store/auth";

// const store = createStore(
//   rootReducer,
//   applyMiddleware(thunk)
// );

const store = configureStore({
  reducer: { imovel: imovelReducer, auth: authReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GeneralContextProvider>
      <AuthContextProvider>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </AuthContextProvider>
    </GeneralContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
