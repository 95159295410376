export default class Imovel {
  constructor(data) {
    //console.log(data);
    this.id = data.id;
    this.titulo = data.titulo;
    this.descricao = data.descricao;
    this.valor = data.valor;
    this.fotoPrincipal =
      "fotoPrincipal" in data
        ? data.fotoPrincipal
        : "/imoveis/img/default/house.jpg";
    this.fotos = data.fotos;
    this.categoria = data.categoria;
    this.endereco = data.endereco;
    this.dormitorios = data.dormitorios;
    this.banheiros = data.banheiros;
    this.cozinhas = data.cozinhas;
    this.garagens = data.garagens;
    this.areaConstruida = data.area_construida;
    this.areaTotal = data.area_total;
    this.paraAlugar = data.para_alugar;
    this.disponivel = data.disponivel;
    this.imovelComercial = data.imovel_comercial;
    this.slug = data.slug;
    this.createdAt = data.createdAt;
  }
}
