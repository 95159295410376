import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  onRemoveFavorite,
  OPERATION_FAILURE,
  selectImovelStore,
} from "../../store/redux/imoveis";
import options from "../../global-options";
import { useEffect, useRef } from "react";
const FavoriteItem = (props) => {
  const dispatch = useDispatch();
  const removeButtonRef = useRef();
  const imovelStore = useSelector(selectImovelStore);

  const onRemoveFavoriteHandler = () => {
    removeButtonRef.current.disabled = true;
    removeButtonRef.current.textContent = "Aguarde...";
    dispatch(onRemoveFavorite(props.id));
  };

  return (
    <div className="col">
      <div className="card h-100">
        <img
          src={options.publicURL + "/" + props.image}
          className="card-img-top"
          alt="..."
        />
        <div className="card-body">
          <h5 className="card-title">{props.title}</h5>
          <p className="card-text">{props.description}</p>
        </div>
        <div className="card-footer text-end">
          <Link to={"/imovel/" + props.id + "/detail"} className="btn btn-info">
            Detalhes
          </Link>
          <button
            onClick={onRemoveFavoriteHandler}
            className="btn btn-danger ms-2"
            ref={removeButtonRef}
          >
            Remover
          </button>
        </div>
      </div>
    </div>
  );
};

export default FavoriteItem;
