import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  onAddFavorite,
  onShowImovel,
  selectImovelById,
  selectImovelStore,
  setFetchError,
  setSaveError,
  setSaveStatus,
} from "../../../store/redux/imoveis";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
//import { useLoaderData } from "react-router-dom";
import ModalComponent from "../../../components/ui/ModalComponent";
//import cssStyles from "./DashboardImovelDetail.module.css";
import MapComponent from "../../../components/ui/MapComponent";
import CurrencyLabelComponent from "../../../components/ui/CurrencyLabelComponent";
import OverlaySpinner from "../../../components/ui/OverlaySpinner";
import { toast, ToastContainer } from "react-toastify";
import OpenModalButton from "../../../components/ui/OpenModalButton";
import { selectAuthStore } from "../../../store/redux/auth";
import options from "../../../global-options";

const DashboardImovelDetail = () => {
  const { imovelId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const imovelStore = useSelector(selectImovelStore);
  const authStore = useSelector(selectAuthStore);
  const imovel = imovelStore.detailImovel; //useSelector((state) => selectImovelById(state, imovelId));
  const [displayPhoto, setDisplayPhoto] = useState(null);

  useEffect(() => {
    if (imovel) {
      setDisplayPhoto(imovel.fotoPrincipal);
    }
  }, [imovel]);

  useEffect(() => {
    //if (!imovel) {
    dispatch(onShowImovel(imovelId));
    //}
  }, [imovelId]);

  useEffect(() => {
    if (imovelStore.fetchError) {
      toast.error(imovelStore.fetchError);
      dispatch(setFetchError(null));
    }
  }, [imovelStore.fetchError]);
  //console.log("IMOVEL: ", imovel);
  function setDisplayPhotoHandler(url) {
    setDisplayPhoto(url);
  }

  const onAddFavoriteHandler = () => {
    console.log("Adding to favorites: ", imovel.id);
    if (!authStore.authData.token) {
      navigate("/login");
    } else {
      dispatch(onAddFavorite(imovel.id));
    }
  };

  useEffect(() => {
    if (imovelStore.saveError) {
      toast.error(imovelStore.saveError);
      dispatch(setSaveError(null));
    }

    if (imovelStore.saveSuccess) {
      toast.success("Favorito adicionado com sucesso!");
      dispatch(setSaveStatus(false));
      setTimeout(() => {
        navigate("/dashboard");
      }, 3000);
    }
  }, [imovelStore.saveSuccess, imovelStore.saveError]);
  if (imovel) {
    return (
      <React.Fragment>
        {imovelStore.isLoading && <OverlaySpinner />}
        {!imovelStore.isLoading && (
          <div className="card my-4">
            <div className="card-header">{imovel.titulo}</div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <ToastContainer />
                  <OpenModalButton anchor={true} modalId="imovelPhoto">
                    <img
                      className="img-fuid"
                      width={"100%"}
                      src={`${options.publicURL}/${displayPhoto}`}
                    ></img>
                  </OpenModalButton>
                </div>
                <div className="col-md-8 d-flex flex-column justify-content-between">
                  <div className="row flex-wrap">
                    {imovel.fotos.map((foto) => (
                      <div className="col-md-4" key={foto.id}>
                        <a
                          alt="Some description"
                          className="anchor-pointer"
                          onClick={setDisplayPhotoHandler.bind(this, foto.url)}
                        >
                          <img
                            className="img-thumbnail d-block"
                            height={"92px"}
                            src={`${options.publicURL}/${foto.url}`}
                          ></img>
                        </a>
                      </div>
                    ))}
                    <div key={imovel.fotoPrincipal} className="col-md-4">
                      <a
                        alt="Some description"
                        className="anchor-pointer"
                        onClick={setDisplayPhotoHandler.bind(
                          this,
                          imovel.fotoPrincipal
                        )}
                      >
                        <img
                          className="img-thumbnail d-block"
                          height={"92px"}
                          src={`${options.publicURL}/${imovel.fotoPrincipal}`}
                        ></img>
                      </a>
                    </div>
                  </div>
                  <p className="card-text">{imovel.descricao}</p>
                  <ul className="list-group">
                    <li className="list-group-item">
                      <b>Tipo: </b>
                      {imovel.categoria.nome} &#45;{" "}
                      {imovel.imovelComercial
                        ? "Imóvel Comercial"
                        : "Imóvel Residencial"}
                    </li>
                    <li className="list-group-item">
                      <b>Anúncio: </b>
                      {imovel.paraAlugar ? "Alugar" : "Vender"}
                    </li>
                    <li className="list-group-item">
                      <b>Valor: </b>
                      <CurrencyLabelComponent value={imovel.valor} />
                    </li>
                    <li className="list-group-item">
                      <b>Endereço:</b> {imovel.endereco.logradouro},
                      {imovel.endereco.numero} <br></br>
                      <b>Bairro:</b> {imovel.endereco.bairro}
                      <br></br>
                      <b>Cidade:</b> {imovel.endereco.cidade}
                      <br></br>
                      <b>CEP:</b> {imovel.endereco.cep}
                    </li>
                    <li className="list-group-item">
                      <b>Dormitórios: </b>
                      {imovel.dormitorios}
                    </li>
                    <li key="2" className="list-group-item">
                      <b>Banheiros: </b>
                      {imovel.banheiros}
                    </li>
                    <li className="list-group-item">
                      <b>Cozinhas: </b>
                      {imovel.cozinhas}
                    </li>
                    <li className="list-group-item">
                      <b>Garagens: </b>
                      {imovel.garagens}
                    </li>
                    <li className="list-group-item">
                      <b>Área Construída: </b>
                      {imovel.areaConstruida}
                    </li>
                    <li className="list-group-item">
                      <b>Área Total: </b>
                      {imovel.areaTotal}
                    </li>
                    {imovel.endereco.latitude && imovel.endereco.longitude && (
                      <li className="list-group-item">
                        <MapComponent
                          lat={imovel.endereco.latitude}
                          lng={imovel.endereco.longitude}
                        />
                      </li>
                    )}
                  </ul>
                  <div className="text-end mt-2">
                    <Link
                      to="/dashboard/meus-imoveis"
                      className="btn btn-secondary ms-2"
                    >
                      Voltar
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <ModalComponent modalId="imovelPhoto" title={imovel.titulo}>
          <img
            className="img-fuid"
            width={"100%"}
            src={`${options.publicURL}/${displayPhoto}`}
          ></img>
        </ModalComponent>

        <ModalComponent
          title="Confirmar interesse"
          modalId="addFavorite"
          actions={
            <React.Fragment>
              <button className="btn btn-secondary" data-bs-dismiss="modal">
                Não
              </button>
              <button
                onClick={onAddFavoriteHandler}
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Sim
              </button>
            </React.Fragment>
          }
        >
          <p>
            Ao manifestar interesse pelo imóvel, este será adicionado aos seus
            favoritos e um de nossos representantes entrará em contato
            apresentar detalhes do negócio. Deseja continuar?
          </p>
        </ModalComponent>
      </React.Fragment>
    );
  } else {
    <div className="alert alert-danger">
      Não foi possível localizar o imóvel pesquisado.
    </div>;
  }
};

export default DashboardImovelDetail;
