import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  fetchUserFavoritos,
  fetchUserImoveis,
  selectImovelStore,
} from "../../store/redux/imoveis";
import SearchResultItem from "../../components/imoveis/SearchResultItem";
import { selectAuthStore, fetchUserProfile } from "../../store/redux/auth";
import SpinnerComponent from "../../components/ui/SpinnerComponent";
const DashboardHome = () => {
  const imovelStore = useSelector(selectImovelStore);
  const authStore = useSelector(selectAuthStore);
  const dispatch = useDispatch();

  const { userImoveis, userFavoritos, isLoading } = imovelStore;

  // useEffect(() => {
  //   dispatch(fetchUserProfile());
  // }, []);

  useEffect(() => {
    if (authStore.authData.user) {
      dispatch(fetchUserImoveis());
      dispatch(fetchUserFavoritos());
    }
  }, [authStore.authData.user]);

  useEffect(() => {}, [imovelStore.userImoveis]);
  return (
    <div>
      <h2 className="mt-4">Painel do Usuário</h2>
      <hr></hr>

      <div className="row">
        <div className="col-md-6">
          <div className="card text-bg-primary mb-3">
            <div className="card-header">Meus Imóveis</div>
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <i
                  className="bi bi-building pe-2"
                  style={{ fontSize: "4rem" }}
                ></i>
                {!isLoading && (
                  <span style={{ fontSize: "4rem" }}>
                    {imovelStore.totalUserImoveis}
                  </span>
                )}

                {isLoading && <SpinnerComponent theme={"lds-dark"} />}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card text-bg-info mb-3">
            <div className="card-header">Favoritos</div>
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <i className="bi bi-star pe-2" style={{ fontSize: "4rem" }}></i>
                {!isLoading && (
                  <span style={{ fontSize: "4rem" }}>
                    {imovelStore.totalUserFavoritos}
                  </span>
                )}
                {isLoading && <SpinnerComponent theme={"lds-dark"} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;
