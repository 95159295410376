import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  useMap,
  Popup,
  useMapEvents,
} from "react-leaflet";
import { MarkerLayer, Marker } from "react-leaflet-marker";
import cssStyles from "./MapComponent.module.css";
import "leaflet/dist/leaflet.css";
import markerIcon from "../../assets/leaflet/marker-icon.png";

const MapComponent = (props) => {
  return (
    <MapContainer
      className={cssStyles.map}
      center={[props.lat, props.lng]}
      zoom={16}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MarkerLayer>
        <Marker position={[props.lat, props.lng]}>
          <img
            src={markerIcon}
            style={{ transform: "translate(0,-41px)" }}
          ></img>
        </Marker>
      </MarkerLayer>
    </MapContainer>
  );
};

export default MapComponent;
