import React, { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NavbarComponent from "./components/ui/NavbarComponent";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import FooterComponent from "./components/ui/FooterComponent";

import { Outlet } from "react-router-dom";
import { selectAuthStore, autoLogin } from "./store/redux/auth";

function App() {
  const authStore = useSelector(selectAuthStore);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authStore.authData.token) {
      dispatch(autoLogin());
    }
    //console.log("AUTH DATA: ", authStore.authData);
  }, [authStore.authData.token, dispatch]);

  return (
    <div className="wrapper">
      <div className="position-relative main-content">
        <NavbarComponent />
        <div className="container">
          <Outlet />
        </div>
      </div>
      <FooterComponent />
    </div>
  );
}

export default App;
