import { Link } from "react-router-dom";
import errorImg from "../../assets/img/stop.jpg";

const DefaultErrorPage = () => {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card mt-4">
            <div className="card-header">
              <h1 className="h5 card-title">Algo deu errado</h1>
            </div>
            <div className="card-body">
              <p className="card-text">
                Talvez o recurso que você tentou acessar não esteja mais
                disponível neste site.
              </p>

              <div className="d-flex justify-content-center">
                <img
                  height={256}
                  src={errorImg}
                  className="rounded-circle"
                  alt="Placa com sinal de 'Parar' em inglês"
                ></img>
              </div>
            </div>
            <div className="card-footer">
              <Link className="btn btn-primary" to="/">
                Voltar
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultErrorPage;
