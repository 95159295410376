import { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Outlet } from "react-router-dom";
import NavbarComponent from "../../components/ui/NavbarComponent";
import {
  selectAuthStore,
  autoLogin,
  fetchUserProfile,
} from "../../store/redux/auth";
import SidebarComponent from "./components/SidebarComponent";
const Dashboard = () => {
  const authStore = useSelector(selectAuthStore);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    //console.log("USER: ", authStore.user);
    if (!authStore.authData.token) {
      navigate("/login");
    }
  }, [authStore.authData.token, navigate]);

  useEffect(() => {
    if (!authStore.authData.token) {
      dispatch(autoLogin());
    }
  }, [authStore.authData.token, dispatch]);

  // useEffect(() => {
  //   dispatch(fetchUserProfile());
  // }, []);
  return (
    <div className="wrapper">
      <NavbarComponent />
      <div className="container-fluid">
        <div className="row">
          <SidebarComponent />
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 dashboard-content">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
