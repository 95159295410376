import { Link } from "react-router-dom";
import options from "../../global-options";
const UserImovelItem = (props) => {
  return (
    <div className="col">
      <div className="card h-100">
        <img
          src={options.publicURL + "/" + props.image}
          className="card-img-top"
          alt="..."
        />
        <div className="card-body">
          <h5 className="card-title">{props.title}</h5>
          <p className="card-text">{props.description}</p>
        </div>
        <div className="card-footer text-end">
          <Link
            to={"/dashboard/imovel/" + props.id + "/detail"}
            className="btn btn-info"
          >
            Detalhes
          </Link>
          <Link
            to={"/dashboard/imovel/" + props.id + "/edit"}
            className="btn btn-primary ms-2"
          >
            Editar
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserImovelItem;
