import "./SpinnerComponent.css";

const SpinnerComponent = (props) => {
  return (
    <div className={"lds-ring " + props.theme}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default SpinnerComponent;
