const ModalComponent = (props) => {
  return (
    <div
      className="modal fade "
      id={props.modalId}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {props.title}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">{props.children}</div>
          {props.actions && <div className="modal-footer">{props.actions}</div>}
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
