import { useEffect, useState } from "react";

const CurrencyLabelComponent = (props) => {
  const { value } = props;
  const [formattedValue, setFormattedValue] = useState("");

  useEffect(() => {
    setFormattedValue(
      new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value)
    );
  }, []);
  return <span>{formattedValue}</span>;
};

export default CurrencyLabelComponent;
