import React, { useReducer, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as bootstrap from "bootstrap";
import { ToastContainer, toast } from "react-toastify";

import { selectAuthStore, signup } from "../../store/redux/auth";
import GeneralContext from "../../store/general";
import OverlaySpinner from "../../components/ui/OverlaySpinner";
import ModalComponent from "../../components/ui/ModalComponent";

const initialState = {
  nome: "",
  cpf: "",
  telefone: "",
  username: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case "NOME": {
      return { ...state, nome: action.payload };
    }
    case "CPF": {
      return { ...state, cpf: action.payload };
    }
    case "TELEFONE": {
      return { ...state, telefone: action.payload };
    }
    case "USERNAME": {
      return { ...state, username: action.payload };
    }
    case "EMAIL": {
      return { ...state, email: action.payload };
    }
    case "SENHA": {
      return { ...state, password: action.payload };
    }
    case "CONFIRMACAO_SENHA": {
      return { ...state, confirmPassword: action.payload };
    }

    case "CLEAR": {
      return initialState;
    }

    default:
      return state;
  }
};
const RegisterPage = () => {
  const navigate = useNavigate();
  const [formState, formDispatch] = useReducer(formReducer, initialState);

  const generalCxt = useContext(GeneralContext);
  const authStore = useSelector(selectAuthStore);
  const dispatch = useDispatch();

  const [modalEl, setModalEl] = useState(null);

  useEffect(() => {
    let tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });

    const modalEl = new bootstrap.Modal(
      document.getElementById("registerSuccessModal")
    );

    setModalEl(modalEl);
  }, []);

  useEffect(() => {
    if (authStore.registerSuccess) {
      formDispatch({ type: "CLEAR" });
      modalEl.show();
    }

    if (authStore.registerError) {
      toast.error("Falha ao realizar cadastro: " + authStore.registerError);
    }
  }, [authStore.registerSuccess, authStore.registerError, modalEl]);

  useEffect(() => {}, [authStore.isLoading]);

  const onSubmitHandler = (event) => {
    event.preventDefault();

    if (formIsValid()) {
      dispatch(signup(formState));
    }
  };

  const onFormChangeHandler = (property, event) => {
    formDispatch({ type: property, payload: event.target.value });
  };

  const formIsValid = () => {
    if (
      formState.password &&
      formState.password !== formState.confirmPassword
    ) {
      return false;
    }

    if (!generalCxt.validaCPF(formState.cpf)) {
      return false;
    }

    return true;
  };

  const highlightInvalid = (event) => {
    const inputEl = event.target;
    if (!inputEl.checkValidity()) {
      inputEl.classList.add("invalid-input");
    } else {
      inputEl.classList.remove("invalid-input");
    }
  };

  const navigateToLogin = () => {
    modalEl.hide();
    navigate("/login");
  };
  return (
    <React.Fragment>
      {authStore.isLoading && <OverlaySpinner />}
      <ModalComponent
        modalId="registerSuccessModal"
        title="Seja Bem-vindo(a)!"
        actions={
          <div>
            <button className="btn btn-success " onClick={navigateToLogin}>
              OK
            </button>
          </div>
        }
      >
        <div className="text-center">
          <i className="bi bi-check-circle-fill fs-1 text-success"></i>
        </div>
        <div>
          Uma mensagem de confirmação foi enviada para o e-mail cadstrado. Você
          só conseguirá fazer login, após confirmar o seu cadastro.
        </div>
      </ModalComponent>
      <div className="wrapper">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 my-4">
              <ToastContainer />
              <div className="card">
                <div className="card-header">
                  <h1 className="h5 card-title">
                    SF - Imobiliária / Cadastro de Usuários
                  </h1>
                </div>
                <div className="card-body">
                  <form onSubmit={onSubmitHandler}>
                    <div className="mb-3">
                      <label htmlFor="nome" className="form-label">
                        Nome *
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="nome"
                        onChange={onFormChangeHandler.bind(this, "NOME")}
                        onBlur={highlightInvalid}
                        required
                        minLength={3}
                        maxLength={255}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Este campo não pode ficar em branco."
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="cpf" className="form-label">
                        CPF *
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="cpf"
                        onChange={onFormChangeHandler.bind(this, "CPF")}
                        onBlur={highlightInvalid}
                        required
                        minLength={11}
                        maxLength={11}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Informe um CPF válido."
                      />
                      {formState.cpf &&
                        !generalCxt.validaCPF(formState.cpf) && (
                          <span className="text-danger">CPF inválido.</span>
                        )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="telefone" className="form-label">
                        Telefone *
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="telefone"
                        onChange={onFormChangeHandler.bind(this, "TELEFONE")}
                        onBlur={highlightInvalid}
                        required
                        minLength={8}
                        maxLength={20}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Este campo não pode ficar em branco."
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        E-mail *
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        onChange={onFormChangeHandler.bind(this, "EMAIL")}
                        onBlur={highlightInvalid}
                        required
                        maxLength={180}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Este campo não pode ficar em branco."
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="username" className="form-label">
                        Nome de Usuário *
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        onChange={onFormChangeHandler.bind(this, "USERNAME")}
                        onBlur={highlightInvalid}
                        required
                        minLength={6}
                        maxLength={180}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Este campo não pode ficar em branco e deve ter, pelo menos, 6 caracteres."
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="senha" className="form-label">
                        Senha (Não use espaços nem [, ])*
                      </label>
                      {/* Caracteres inválidos: []<>`{}*/}
                      <input
                        type="password"
                        className="form-control"
                        id="senha"
                        onChange={onFormChangeHandler.bind(this, "SENHA")}
                        onBlur={highlightInvalid}
                        required
                        minLength={6}
                        maxLength={32}
                        pattern={
                          "[A-Za-z0-9~`!@#$%^&*()_+={}|:;\"'<,>.?/-]{6,32}"
                        }
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="A senha deve ter no mínimo 6 e, no máximo 32 caracteres."
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="confirmacaoSenha" className="form-label">
                        Confirmar Senha *
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="confirmacaoSenha"
                        onChange={onFormChangeHandler.bind(
                          this,
                          "CONFIRMACAO_SENHA"
                        )}
                        onBlur={highlightInvalid}
                        required
                        minLength={6}
                        maxLength={32}
                        pattern={
                          "[A-Za-z0-9~`!@#$%^&*()_+={}|:;\"'<,>.?/-]{6,32}"
                        }
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Este campo não pode ficar em branco."
                      />
                      {formState.password !== formState.confirmPassword && (
                        <span className="text-danger">
                          As senhas não coincidem.
                        </span>
                      )}
                    </div>

                    <button type="submit" className="btn btn-primary">
                      Enviar
                    </button>
                    <Link to="/" className="ms-2 btn btn-secondary">
                      Cancelar
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )
    </React.Fragment>
  );
};

export default RegisterPage;
