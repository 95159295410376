import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useReducer,
} from "react";
import GeneralContext from "../../store/general";
import { useSelector, useDispatch } from "react-redux";
//import { onSearch } from "../../store/redux/imoveis/actions";
import SpinnerComponent from "../../components/ui/SpinnerComponent";
import SearchResult from "../../components/imoveis/SearchResult";
import {
  onSearch,
  selectImovelStore,
  selectSearchParams,
  updateSearchParams,
} from "../../store/redux/imoveis";
import PaginatorComponent from "../../components/ui/PaginatorComponent";

// const searchParamsInitialState = {
//   uf: "",
//   cidade: "",
//   bairro: "",
//   desc: "",
//   paraAlugar: false,
//   imovelComercial: false,
//   categoria: "todas",
// };
// const searchParamsReducer = (state, action) => {
//   switch (action.type) {
//     case "CLEAR": {
//       return {};
//     }
//     case "UF": {
//       return { ...state, uf: action.payload };
//     }
//     case "CIDADE": {
//       return { ...state, cidade: action.payload };
//     }
//     case "BAIRRO": {
//       return { ...state, bairro: action.payload };
//     }
//     case "DESCRICAO": {
//       return {
//         ...state,
//         desc: action.payload,
//       };
//     }
//     case "CATEGORIA": {
//       return { ...state, categoria: action.payload };
//     }
//     case "IMOVEL_COMERCIAL": {
//       return { ...state, imovelComercial: action.payload };
//     }
//     case "PARA_ALUGAR": {
//       return { ...state, paraAlugar: action.payload };
//     }

//     default: {
//       return state;
//     }
//   }
// };

const formReducer = (state, action) => {
  return { ...state, [action.payload.field]: action.payload.value };
};

const ImovelSearchPage = () => {
  const [formState, formDispatch] = useReducer(formReducer, {
    dormitorios: 0,
    banheiros: 0,
    cozinhas: 0,
    garagens: 0,
  });
  const ufSelectRef = useRef();
  const [district, setDistrict] = useState("");
  const [description, setDescription] = useState("");
  //const [searchParams, setSearchParams] = useState({});
  const cidadeSelectRef = useRef();
  const bairroInputRef = useRef();

  const descricaoInputRef = useRef();
  const paraAlugarCheckRef = useRef();
  const imovelComercialSelectRef = useRef();
  const categoriaSelectRef = useRef();
  const imovelStore = useSelector(selectImovelStore);
  const dispatch = useDispatch();
  const searchParams = useSelector(selectSearchParams);

  // const [searchParamsState, searchParamsDispatch] = useReducer(
  //   searchParamsReducer,
  //   searchParamsInitialState
  // );

  const generalCxt = useContext(GeneralContext);

  useEffect(() => {
    generalCxt.fetchAndSetCategorias();
    setFormValues();
  }, []);

  useEffect(() => {}, [generalCxt.cidades]);

  const setFormValues = () => {
    ufSelectRef.current.value = searchParams.uf;
    cidadeSelectRef.current.value = searchParams.cidade;
    categoriaSelectRef.current.value = searchParams.categoria;
    bairroInputRef.current.value = searchParams.bairro;
    descricaoInputRef.current.value = searchParams.desc;
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (canPerformSearch()) {
        onSearchHandler();
      }
    }, 1000);

    return () => clearTimeout(timerId);
  }, [searchParams]);

  const ufs = generalCxt.ufs;

  // const disableInputs = (disabled) => {
  //   bairroInputRef.current.disabled = disabled;
  //   descricaoInputRef.current.disabled = disabled;
  //   imovelComercialSelectRef.current.disabled = disabled;
  //   categoriaSelectRef.current.disabled = disabled;
  //   paraAlugarCheckRef.current.disabled = disabled;
  // };

  const onSearchHandler = async () => {
    dispatch(onSearch(imovelStore.searchParams));
  };

  const onUFSelectChangeHandler = async (event) => {
    const selectedUF = event.target.value;

    if (ufs.includes(selectedUF)) {
      generalCxt.onSearchCidades(selectedUF);
      cidadeSelectRef.current.disabled = false;

      //setSearchParams((searchParams) => ({ ...searchParams, uf: selectedUF }));
      //searchParamsDispatch({ type: "UF", payload: selectedUF });
      dispatch(updateSearchParams({ param: "uf", value: selectedUF }));
    } else {
      cidadeSelectRef.current.disabled = true;
    }

    //disableInputs(true);
  };

  const onCitySelectChangeHandler = (event) => {
    const selectedCity = event.target.value;
    const cityIsValid = generalCxt.cidades.some(
      (city) => city.nome === selectedCity
    );

    if (cityIsValid) {
      // disableInputs(false);
      // setSearchParams((searchParams) => ({
      //   ...searchParams,
      //   cidade: selectedCity,
      // }));
      //searchParamsDispatch({ type: "CIDADE", payload: selectedCity });
      dispatch(updateSearchParams({ param: "cidade", value: selectedCity }));
    }
    // else {
    //   disableInputs(true);
    // }
  };

  const onDistrictInputChangeHandler = (event) => {
    const districtName = event.target.value;
    setDistrict(districtName);

    dispatch(updateSearchParams({ param: "bairro", value: districtName }));
  };

  const onDescriptionInputChangeHandler = (event) => {
    const description = descricaoInputRef.current.value; //event.target.value;
    setDescription(description);

    dispatch(updateSearchParams({ param: "desc", value: description }));
  };

  function onCategoriaSelectChangeHandler() {
    const categoriaId = categoriaSelectRef.current.value;
    //searchParamsDispatch({ type: "CATEGORIA", categoriaId });
    dispatch(updateSearchParams({ param: "categoria", value: categoriaId }));
  }

  const onImovelComercialSelectChangeHandler = () => {
    const isImovelComercial =
      imovelComercialSelectRef.current.value === "1" ? true : false;
    // searchParamsDispatch({
    //   type: "IMOVEL_COMERCIAL",
    //   payload: isImovelComercial,
    // });
    dispatch(
      updateSearchParams({ param: "imovelComercial", value: isImovelComercial })
    );
  };

  const onParaAlugarCheckChangeHandler = () => {
    const isParaAlugar = paraAlugarCheckRef.current.checked ? true : false;

    //searchParamsDispatch({ type: "PARA_ALUGAR", payload: isParaAlugar });
    dispatch(updateSearchParams({ param: "paraAlugar", value: isParaAlugar }));
  };

  const canPerformSearch = () => {
    return isUFDefined() && searchParams.cidade !== "";
  };

  const isUFDefined = () => {
    return searchParams.uf !== "";
  };

  const onUpdateSearchParamsHandler = (paramName, event) => {
    // console.log("Param name: ", paramName);
    // console.log("Event: ", event);
    formDispatch({ payload: { field: paramName, value: event.target.value } });
    dispatch(
      updateSearchParams({ param: paramName, value: event.target.value })
    );
  };
  return (
    <React.Fragment>
      <div className="row justify-content-center mt-5 position-relative">
        <div className="col-12">
          <div
            className={
              "position-fixed px-3 py-2 shadow bg-dark end-0 me-2 rounded-circle floating-button " +
              (canPerformSearch() ? " floating-button-show" : "")
            }
          >
            <a
              className="anchor-button "
              data-bs-toggle="offcanvas"
              href="#offcanvasExample"
              role="button"
              aria-controls="offcanvasExample"
            >
              <i
                style={{ color: "#eee", fontSize: "2rem" }}
                className="bi bi-plus"
              ></i>
            </a>
          </div>
          <div
            className="offcanvas offcanvas-start"
            tabIndex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                Mais Filtros <i className="bi bi-funnel-fill"></i>
              </h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div>
                <div className="mb-3">
                  <label className="form-label">Dormitórios</label>
                  <input
                    value={formState.dormitorios}
                    onChange={(e) =>
                      onUpdateSearchParamsHandler("dormitorios", e)
                    }
                    className="form-control"
                    type="number"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Banheiros</label>
                  <input
                    value={formState.banheiros}
                    onChange={(e) =>
                      onUpdateSearchParamsHandler("banheiros", e)
                    }
                    className="form-control"
                    type="number"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Cozinhas</label>
                  <input
                    value={formState.cozinhas}
                    onChange={(e) => onUpdateSearchParamsHandler("cozinhas", e)}
                    className="form-control"
                    type="number"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Garagens</label>
                  <input
                    value={formState.garagens}
                    onChange={(e) => onUpdateSearchParamsHandler("garagens", e)}
                    className="form-control"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>
          <form method="POST" action="#">
            <div className="row">
              <div className="col-md-2">
                <div className="input-group mb-2">
                  <select
                    ref={ufSelectRef}
                    id="inputState"
                    className="form-select"
                    onChange={onUFSelectChangeHandler}
                  >
                    <option defaultValue={true} value="">
                      UF
                    </option>
                    {ufs.map((uf) => (
                      <option key={uf} value={uf}>
                        {uf}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="input-group mb-2">
                  <select
                    disabled={!isUFDefined()}
                    ref={cidadeSelectRef}
                    id="inputState"
                    className="form-select"
                    onChange={onCitySelectChangeHandler}
                  >
                    <option defaultValue={true} value="">
                      Cidade
                    </option>
                    {generalCxt.cidades.map((city) => (
                      <option key={city.id} value={city.nome}>
                        {city.nome}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="input-group mb-2">
                  <input
                    type="text"
                    className="form-control"
                    name="s"
                    placeholder="Bairro. Ex.: Centro"
                    disabled={!canPerformSearch()}
                    ref={bairroInputRef}
                    onChange={onDistrictInputChangeHandler}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="input-group mb-2">
                  <input
                    type="text"
                    className="form-control"
                    name="s"
                    placeholder="Digite um termo. Ex.: Casa de praia"
                    disabled={!canPerformSearch()}
                    ref={descricaoInputRef}
                    onChange={onDescriptionInputChangeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="row my-4">
              <div className="col-md-4">
                <div className="input-group mb-2">
                  <select
                    id="inputState"
                    className="form-select"
                    ref={imovelComercialSelectRef}
                    disabled={!canPerformSearch()}
                    onChange={onImovelComercialSelectChangeHandler}
                  >
                    <option defaultValue={true} value="0">
                      Residencial
                    </option>
                    <option value="1">Comercial</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="input-group mb-2">
                  <select
                    id="inputState"
                    className="form-select"
                    ref={categoriaSelectRef}
                    disabled={!canPerformSearch()}
                    onChange={onCategoriaSelectChangeHandler}
                  >
                    <option defaultValue={true} value="todas">
                      Todas
                    </option>
                    {generalCxt.categorias.map((cat) => (
                      <option key={cat.id} value={cat.id}>
                        {cat.nome}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-check bg-light py-2 ps-5 rounded mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    ref={paraAlugarCheckRef}
                    disabled={!canPerformSearch()}
                    onChange={onParaAlugarCheckChangeHandler}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Imóveis para Alugar
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {imovelStore.isLoading ? (
        <div className="d-flex justify-content-center mt-5">
          <SpinnerComponent />
        </div>
      ) : (
        <SearchResult imoveis={imovelStore.imoveis} />
      )}

      {imovelStore.paginationData && (
        <PaginatorComponent paginationData={imovelStore.paginationData} />
      )}
    </React.Fragment>
  );
};

export default ImovelSearchPage;
