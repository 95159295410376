import React, { useState } from "react";
import Categoria from "../model/Categoria";
import options from "../global-options";

const GeneralContext = React.createContext(null);

export const GeneralContextProvider = (props) => {
  const [cidades, setCidades] = useState([]);
  const [categorias, setCategorias] = useState([]);

  const ibgeURL =
    "https://servicodados.ibge.gov.br/api/v1/localidades/estados/";
  const baseURL = options.baseURL; //"http://localhost:8000/api/v2";
  const generalState = {
    baseURL,
    ibgeURL,
    ufs: [
      "AC",
      "AL",
      "AP",
      "AM",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MS",
      "MT",
      "MG",
      "PA",
      "PB",
      "PR",
      "PE",
      "PI",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SP",
      "SE",
      "TO",
    ],

    cidades,
    categorias,
    //setCategorias,
    fetchAndSetCategorias: async () => {
      const response = await fetch(`${baseURL}/public/categorias`);
      const responseData = await response.json();

      if ("hydra:member" in responseData) {
        const categorias = responseData["hydra:member"].map(
          (cat) => new Categoria(cat.id, cat.nome)
        );

        //console.log("CATEGORIAS: ", categorias);
        setCategorias(categorias);
      }
    },
    onSearchCidades: async (uf) => {
      const response = await fetch(`${ibgeURL}${uf}/municipios`);
      const responseData = await response.json();

      const cidades = responseData.map((cityObj) => ({
        id: cityObj.id,
        nome: cityObj.nome,
      }));

      setCidades(cidades);
    },
    validaCPF(cpf) {
      // Extrai somente os números
      cpf = cpf.replace(/[^0-9]/g, "");

      // Verifica se foi informado todos os digitos corretamente
      if (cpf.length != 11) {
        return false;
      }

      // Verifica se foi informada uma sequência de digitos repetidos. Ex: 111.111.111-11
      if (cpf.match(/(\d)\1{10}/)) {
        return false;
      }

      // Faz o calculo para validar o CPF
      for (let t = 9; t < 11; t++) {
        let d = 0;
        let c = 0;
        for (d = 0, c = 0; c < t; c++) {
          d += cpf[c] * (t + 1 - c);
        }
        d = ((10 * d) % 11) % 10;
        if (cpf[c] != d) {
          return false;
        }
      }
      return true;
    },
  };

  return (
    <GeneralContext.Provider value={generalState}>
      {props.children}
    </GeneralContext.Provider>
  );
};

export default GeneralContext;
