import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import FavoriteItem from "../../../components/imoveis/FavoriteItem";
import UserImovelItem from "../../../components/imoveis/UserImovelItem";
import {
  OPERATION_FAILURE,
  selectImovelStore,
} from "../../../store/redux/imoveis";

const FavoritesPage = () => {
  const imovelStore = useSelector(selectImovelStore);
  useEffect(() => {
    if (imovelStore.operationStatus === OPERATION_FAILURE) {
      toast.error("Houve um erro ao tentar remover o favorito.");
    }
  }, [imovelStore.operationStatus]);
  return (
    <React.Fragment>
      <h1>Favoritos (Interesses)</h1>
      <hr></hr>
      <ToastContainer />
      <div className="row row-cols-1 row-cols-md-3 g-4">
        {imovelStore.userFavoritos.map((fav) => (
          <FavoriteItem
            image={fav.imovel.fotoPrincipal}
            title={fav.imovel.titulo}
            description={fav.imovel.descricao}
            id={fav.id}
            key={fav.id}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default FavoritesPage;
