import React from "react";

const OpenModalButton = (props) => {
  return (
    <React.Fragment>
      {props.anchor ? (
        <a
          className="anchor-pointer"
          data-bs-toggle="modal"
          tabIndex={-1}
          data-bs-target={"#" + props.modalId}
        >
          {" "}
          {props.children}
        </a>
      ) : (
        <button
          type="button"
          className={"btn " + props.classes}
          data-bs-toggle="modal"
          data-bs-target={"#" + props.modalId}
        >
          {props.children}
        </button>
      )}
    </React.Fragment>
  );
};

export default OpenModalButton;
