import React, { useState, useEffect } from "react";
import ModalComponent from "../ui/ModalComponent";
import SpinnerComponent from "../ui/SpinnerComponent";
import SearchResultItem from "./SearchResultItem";
const SearchResult = (props) => {
  const [modalImage, setModalImage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const { imoveis } = props;

  useEffect(() => {}, [imoveis, modalImage, modalTitle]);
  return (
    <div>
      {imoveis.map((imovel) => (
        <SearchResultItem
          key={imovel.id}
          setModalImage={setModalImage}
          setModalTitle={setModalTitle}
          imovel={imovel}
        />
      ))}
      <ModalComponent modalId="resultPageModal" title={modalTitle}>
        <div className="d-flex justify-content-center">
          <img src={modalImage} className="img-fuid" width={"100%"}></img>
        </div>
      </ModalComponent>
    </div>
  );
};

export default SearchResult;
