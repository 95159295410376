import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserImovelItem from "../../../components/imoveis/UserImovelItem";
import PaginatorComponent from "../../../components/ui/PaginatorComponent";
import {
  selectImovelStore,
  fetchUserImoveis,
  setSaveStatus,
} from "../../../store/redux/imoveis";
import SpinnerComponent from "../../../components/ui/SpinnerComponent";
import DashboardHeader from "../components/DashboardHeader";
import { toast, ToastContainer } from "react-toastify";
const UserImoveis = () => {
  const imovelStore = useSelector(selectImovelStore);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const paginationData = imovelStore.userImoveisPaginationData;

  const onLoadPage = (page) => {
    //console.log("LOADING PAGE: ", page);
    dispatch(fetchUserImoveis(page));
  };

  const goToNewImovelPage = () => {
    navigate("/dashboard/new-imovel");
  };
  useEffect(() => {
    //console.log("IS LOADING: ", imovelStore.isLoading);
  }, [imovelStore.userImoveis, imovelStore.isLoading]);

  useEffect(() => {
    // if (imovelStore.saveSuccess) {
    //   toast.success("Imovel salvo com sucesso!");
    //   dispatch(setSaveStatus(false));
    // }
    dispatch(fetchUserImoveis());
  }, []);
  return (
    <React.Fragment>
      <DashboardHeader title="Meus Imóveis">
        <div className="btn-group me-2">
          <ToastContainer />
          <button
            type="button"
            onClick={goToNewImovelPage}
            className="btn btn-sm btn-outline-primary"
          >
            Novo Imóvel
          </button>
        </div>
      </DashboardHeader>

      {imovelStore.isLoading && (
        <div className="d-flex justify-content-center py-4">
          <SpinnerComponent theme="lds-blue" />
        </div>
      )}
      {!imovelStore.isLoading && (
        <div className="row row-cols-1 row-cols-md-3 g-4">
          {imovelStore.userImoveis.map((imovel) => (
            <UserImovelItem
              image={imovel.fotoPrincipal}
              title={imovel.titulo}
              description={imovel.descricao}
              id={imovel.id}
              key={imovel.id}
            />
          ))}
        </div>
      )}
      {paginationData && (
        <PaginatorComponent
          paginationData={paginationData}
          loadPage={onLoadPage}
        />
      )}
    </React.Fragment>
  );
};

export default UserImoveis;
