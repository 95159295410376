import {
  createBrowserRouter,
  redirect,
  Router,
  RouterProvider,
} from "react-router-dom";
import DashboardHome from "./pages/dashboard/DashboardHome";
import OverlaySpinner from "./components/ui/OverlaySpinner";
import ProfilePage from "./pages/dashboard/user/ProfilePage";
import UserImoveis from "./pages/dashboard/user/UserImoveis";
import FavoritesPage from "./pages/dashboard/user/FavoritesPage";
import NewImovel from "./pages/dashboard/imovel/NewImovel";
import App from "./App";
import Dashboard from "./pages/dashboard";
import LoginPage from "./pages/auth/LoginPage";
import ImovelDetail from "./pages/imovel/ImovelDetail";
import DashboardImovelDetail from "./pages/dashboard/imovel/DashboardImovelDetail";
import ImovelSearchPage from "./pages/imovel/ImovelSearchPage";
import DefaultErrorPage from "./pages/error/DefaultErrorPage";
import RegisterPage from "./pages/register/RegisterPage";
import EditImovel from "./pages/dashboard/imovel/EditImovel";
import options from "./global-options";
import Imovel from "./model/Imovel";

const router = createBrowserRouter([
  {
    path: "",
    element: <App />,
    errorElement: <DefaultErrorPage />,
    children: [
      {
        path: "",
        element: <ImovelSearchPage />,
      },
      {
        path: "imovel/:imovelId/detail",
        element: <ImovelDetail />,
        loader: async ({ params }) => {
          const response = await fetch(
            `${options.baseURL}/public/imoveis/${params.imovelId}`
          );

          if (response.status === 200) {
            const responseData = await response.json();
            //console.log("FETCH SINGLE IMOVEL RESPONSE DATA: ", responseData);
            return new Imovel(responseData);
          } else {
            return redirect("/");
          }
        },
      },
      // {
      //   path: "spinner",
      //   element: <OverlaySpinner />,
      // },
    ],
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    errorElement: <DefaultErrorPage />,
    children: [
      {
        path: "",
        element: <DashboardHome />,
      },
      {
        path: "perfil",
        element: <ProfilePage />,
      },
      {
        path: "meus-imoveis",
        element: <UserImoveis />,
      },
      {
        path: "favoritos",
        element: <FavoritesPage />,
      },
      {
        path: "new-imovel",
        element: <NewImovel />,
      },
      {
        path: "imovel/:imovelId/detail",
        element: <DashboardImovelDetail />,
      },
      {
        path: "imovel/:imovelId/edit",
        element: <EditImovel />,
      },
    ],
  },
]);

export default router;
